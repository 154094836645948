import React from "react"

import Sidebar from "./Sidebar"
import Products from "./Products"

const SectionProducts = () => (
  <section className="section-products">
    <div className="container">
      <div className="flex-wrapper">
        <Sidebar />
        <Products />
      </div>
    </div>
  </section>
)

export default SectionProducts
