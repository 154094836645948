import React from "react"
import Header from "components/core/layout/Header"
import SectionHero from "components/presentation/multi-step/SectionHero"
import SectionSteps from "components/presentation/multi-step/SectionSteps"
import Footer from "components/core/layout/Footer"

import "./multi-step.scss"

const MultiStep = () => (
  <main id="page-multi-step" className="page">
    <Header />
    <SectionHero />
    <SectionSteps />
    <Footer />
  </main>
)

export default MultiStep
