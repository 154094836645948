import React from "react"

import CardProduct from "components/core/CardProduct"
import "./style.scss"

import productImage1 from "assets/img/general/product-general-1.png"
import productImage2 from "assets/img/general/product-general-2.png"
import productImage3 from "assets/img/general/product-general-3.png"

const SectionProducts = () => {
  const products = [
    {
      image: productImage1,
      title: "Massa Corrida \nPVA 1,4 kg",
      price: "R$ 15,20",
      installment: "em até 5x de 3,04 sem juros",
    },
    {
      image: productImage2,
      title: "Kit com 3 Pinceis de Pintura cabo Madeira",
      price: "R$ 33,90",
      installment: "em até 10x de 3,39 sem juros",
    },
    {
      image: productImage3,
      title: "Lixa Massa A257",
      price: "R$ 2,20",
      installment: "em até 2x de 1,10 sem juros",
    },
    {
      image: productImage1,
      title: "Massa Corrida \nPVA 1,4 kg",
      price: "R$ 15,20",
      installment: "em até 5x de 3,04 sem juros",
    },
    {
      image: productImage2,
      title: "Kit com 3 Pinceis de Pintura cabo Madeira",
      price: "R$ 33,90",
      installment: "em até 10x de 3,39 sem juros",
    },
  ]

  return (
    <section className="section-general-products">
      <div className="container">
        <div className="headline">
          <h2 className="headline-title">
            Aproveite e leve também itens que você pode precisar
          </h2>
        </div>

        <div className="flex-wrapper">
          {products.map((product, index) => (
            <CardProduct
              image={product.image}
              title={product.title}
              price={product.price}
              installment={product.installment}
              key={`product-${index}`}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionProducts
