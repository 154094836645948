import React from "react"
import Header from "components/core/layout/Header"
import Footer from "components/core/layout/Footer"
import SectionProducts from "components/presentation/general/SectionProducts"
import SectionCart from "components/presentation/Cart/SectionCart"

import logoIcon from "assets/img/cart/logo-icon.png"
import productImage from "assets/img/general/product-image-1.png"

import "./cart.scss"

const Cart = () => (
  <main id="page-cart" className="page">
    <Header />
    <SectionProducts />
    <SectionCart />

    <div className="modal-pay">
      <div className="modal-pay--background"></div>

      <div className="modal-pay--wrapper">
        <div className="modal-pay--dots-1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="modal-pay--dots-2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="modal-pay--logo">
          <img src={logoIcon} alt="Imagem - Suvinil" />
        </div>

        <div className="modal-pay--content">
          <p>
            Sua compra
            <br />
            está sendo processada!
          </p>
        </div>

        <div className="modal-pay--product">
          <img src={productImage} alt="Imagem - Suvinil" />
        </div>
      </div>
    </div>
    <Footer />
  </main>
)

export default Cart
