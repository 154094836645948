import React from "react"
import Header from "components/core/layout/Header"
import SectionHero from "components/presentation/Checkout/SectionHero"
import SectionCheckout from "components/presentation/Checkout/SectionCheckout"
import SectionPainters from "components/presentation/general/SectionPainters"
import SectionBanners from "components/presentation/general/SectionBanners"
import Footer from "components/core/layout/Footer"
import "./checkout.scss"

const Checkout = () => (
  <main id="page-checkout" className="page">
    <Header />
    <SectionHero />
    <SectionCheckout />
    <SectionPainters />
    <SectionBanners secondaryStyle />
    <Footer />
  </main>
)

export default Checkout
