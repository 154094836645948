import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import urls from "config/constants/urls"
import Button from "components/core/Button"

import {
  methodPayment,
  count1,
  count2,
  star,
} from "assets/vectors/product/general"

const Right = () => {
  const [distributor, setDistributor] = useState("Varejão Das Tintas")
  const [count, setCount] = useState(1)

  useEffect(() => {
    const page = document.querySelector("#page-product")
    const distributors = page.querySelector(".section-hero .right-distributors")
    const listing = distributors.querySelector(".right-distributors--listing")
    const items = listing.querySelectorAll(".item")

    listing.addEventListener("scroll", function () {
      const height = listing.offsetHeight
      const scrollTop = listing.scrollTop
      const scrollHeight = listing.scrollHeight
      const scrollMax = scrollHeight - height

      if (scrollMax - scrollTop <= 7) {
        distributors.classList.add("scrolled")
      } else {
        distributors.classList.remove("scrolled")
      }
    })

    for (let y = 0; y < items.length; y++) {
      const item = items[y]
      const button = item.querySelector("button")

      button.addEventListener("click", function () {
        for (let i = 0; i < items.length; i++) {
          items[i].classList.remove("active")
        }

        item.classList.add("active")
      })
    }
  }, [])

  return (
    <div className="right">
      <div className="right-distributors">
        <div className="right-distributors--listing">
          <div className="item active">
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
              className="item-button"
              onClick={() => setDistributor("Varejão Das Tintas")}
            >
              Selecionar opção
            </button>

            <div className="item-star">{star}</div>

            <div className="item-title">Varejão Das Tintas</div>

            <div className="item-description">
              <p>
                Frete: R$15,60
                <br />2 dia(s) úteis
              </p>
            </div>

            <div className="item-price">R$ 125,90</div>
          </div>

          <div className="item">
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
              className="item-button"
              onClick={() => setDistributor("Telha Norte")}
            >
              Selecionar opção
            </button>

            <div className="item-title">Telha Norte</div>

            <div className="item-description">
              <p>
                Frete: R$25,60
                <br />2 dia(s) úteis
              </p>
            </div>

            <div className="item-price">R$ 128,90</div>
          </div>

          <div className="item">
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
              className="item-button"
              onClick={() => setDistributor("Varejão Das Tintas 2")}
            >
              Selecionar opção
            </button>

            <div className="item-title">Varejão Das Tintas 2</div>

            <div className="item-description">
              <p>
                Frete: R$25,60
                <br />2 dia(s) úteis
              </p>
            </div>

            <div className="item-price">R$ 135,90</div>
          </div>
        </div>
      </div>

      <div className="right-price">
        <div className="right-price--title">
          Vendido e entregue por <span>{distributor}</span>
        </div>

        <div className="right-price--value">R$ 125,90</div>
        <div className="right-price--installment">
          em até 10x de 12,59 sem juros
        </div>

        <div className="right-price--count">
          <button
            type="button"
            title="Diminuir"
            aria-label="Diminuir"
            onClick={() => (count === 1 ? "" : setCount(count - 1))}
          >
            {count1}
          </button>

          <span>{count === 1 ? `${count} unidade` : `${count} unidades`}</span>

          <button
            type="button"
            title="Aumentar"
            aria-label="Aumentar"
            onClick={() => (count === 99 ? "" : setCount(count + 1))}
          >
            {count2}
          </button>
        </div>

        <div className="right-price--cta">
          <Link to={urls.LINKS.CART}>
            <Button>Comprar</Button>
          </Link>
        </div>
      </div>

      <div className="right-method-payment">
        {methodPayment}
        <span>Veja as formas de pagamento</span>
      </div>
    </div>
  )
}

export default Right
