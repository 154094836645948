import React from "react"

import image from "assets/img/get-inspired/hero-background.jpg"

const SectionHero = () => (
  <section className="section-hero">
    <div className="background">
      <img src={image} alt="Imagem - Suvinil" />
    </div>

    <div className="wrapper">
      <div className="container">
        <div className="content">
          <h1 className="content-title">Inspire-se</h1>

          <div className="content-description">
            <p>
              A Suvinil preparou diversas fontes de inspiração para você somar
              as suas ideias e ainda aprender com tutoriais e dicas a obter os
              melhores resultados.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionHero
