import React, { useState, useEffect } from "react"

import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import Step6 from "./Step6"
import Step7 from "./Step7"
import Step8 from "./Step8"
import Button from "components/core/Button"
import CardProduct from "components/core/CardProduct"
import SectionVideos from "components/presentation/general/SectionVideos"
import SectionPainters from "components/presentation/general/SectionPainters"

import shareIcon from "assets/img/multi-step/share-icon.svg"
import resetIcon from "assets/img/multi-step/reset-result.svg"
import productImage1 from "assets/img/general/product-image-1.png"

const SectionSteps = () => {
  const [tab, setTab] = useState(1)
  const [finished, setFinished] = useState(false)
  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [step4, setStep4] = useState(false)
  const [step5, setStep5] = useState(false)
  const [step6, setStep6] = useState(false)
  const [step7, setStep7] = useState(false)
  const [step8, setStep8] = useState(false)

  const resetResult = () => {
    window.scrollTo(0, 235)
    setTab(1)
    setStep1(false)
    setStep2(false)
    setStep3(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
    setStep7(false)
    setStep8(false)
    setTimeout(() => setFinished(false), 100)
  }

  useEffect(() => {
    setTimeout(() => setTab(1), 1000)
  }, [])

  useEffect(() => {
    if (step1) setTab(tab + 1)
  }, [step1, step2, step3, step4, step5, step6, step7])

  return (
    <section
      className={
        finished ? "section section-steps finished" : "section section-steps"
      }
    >
      <div className="container">
        <div className={finished ? "step-progress removed" : "step-progress"}>
          <div
            className="step-progress--line"
            data-progress={tab ? (tab >= 9 ? "8" : tab) : "1"}
          ></div>

          <div className="step-progress--count">
            <span>{tab ? (tab >= 9 ? "8" : tab) : "1"}</span>
            <span>/</span>
            <span>8</span>
          </div>

          <button
            type="button"
            className={
              !tab || tab === 1
                ? "step-progress--back disabled"
                : "step-progress--back"
            }
            title="Voltar passo"
            aria-label="Voltar passo"
            onClick={() => setTab(tab - 1)}
          >
            <svg
              width="7"
              height="14"
              viewBox="0 0 7 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 13L1.27972 7.73383C0.906759 7.31773 0.906759 6.68227 1.27972 6.26618L6 1"
                stroke="#999999"
                strokeWidth="1.5"
              />
            </svg>

            <span>Voltar para pergunta anterior</span>
          </button>
        </div>

        <div className={finished ? "tabs removed" : "tabs"}>
          <Step1 tab={tab} setTab={setTab} step={step1} setStep={setStep1} />
          <Step2 tab={tab} setTab={setTab} step={step2} setStep={setStep2} />
          <Step3 tab={tab} setTab={setTab} step={step3} setStep={setStep3} />
          <Step4 tab={tab} setTab={setTab} step={step4} setStep={setStep4} />
          <Step5 tab={tab} setTab={setTab} step={step5} setStep={setStep5} />
          <Step6 tab={tab} setTab={setTab} step={step6} setStep={setStep6} />
          <Step7 tab={tab} setTab={setTab} step={step7} setStep={setStep7} />
          <Step8
            tab={tab}
            setTab={setTab}
            step={step8}
            setStep={setStep8}
            setFinished={setFinished}
          />
        </div>

        <div className={finished ? "result active" : "result"}>
          <div className="result-title">Seu resultado foi:</div>

          <div className="result-block">
            <button
              type="button"
              className="result-block--share"
              title="Compartilhar nas redes sociais"
              aria-label="Compartilhar nas redes sociais"
            >
              <img src={shareIcon} alt="Imagem - Suvinil" />
            </button>

            <div className="result-block--products">
              <CardProduct
                headlineBackground="EDC4C4"
                headlineColor="000000"
                headlineTitle="Margaridinha-rosa"
                image={productImage1}
                title="Tinta Suvinil Fosco Completo"
                price="R$ 125,90"
                installment="em até 10x de 12,59 sem juros"
              />

              <CardProduct
                headlineBackground="FAE4E4"
                headlineColor="000000"
                headlineTitle="Conto de fadas"
                image={productImage1}
                title="Tinta Suvinil Fosco Completo"
                price="R$ 125,90"
                installment="em até 10x de 12,59 sem juros"
              />

              <CardProduct
                headlineBackground="F0DCD5"
                headlineColor="000000"
                headlineTitle="Pérola-rosa"
                image={productImage1}
                title="Tinta Suvinil Fosco Completo"
                price="R$ 125,90"
                installment="em até 10x de 12,59 sem juros"
              />
            </div>

            <div className="result-block--actions">
              <div className="save-result">
                <Button>Salvar resultado </Button>
              </div>

              <div className="reset-result">
                <button
                  type="button"
                  title="Refazer"
                  aria-label="Refazer"
                  onClick={() => resetResult()}
                >
                  <img src={resetIcon} alt="Imagem - Suvinil" />
                  <span>Refazer</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={finished ? "hidden-sections active" : "hidden-sections"}
        >
          <SectionVideos />
          <SectionPainters />
        </div>
      </div>
    </section>
  )
}

export default SectionSteps
