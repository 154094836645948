import React from "react"
import Header from "components/core/layout/Header"
import SectionHero from "components/presentation/get-inspired/SectionHero"
import SectionCards from "components/presentation/get-inspired/SectionCards"
import SectionConsultancy from "components/presentation/get-inspired/SectionConsultancy"
import SectionVideos from "components/presentation/general/SectionVideos"
import Footer from "components/core/layout/Footer"

import "./get-inspired.scss"

const GetInspired = () => (
  <main id="page-get-inspired" className="page">
    <Header />
    <SectionHero />
    <SectionCards />
    <SectionConsultancy />
    <SectionVideos />
    <Footer />
  </main>
)

export default GetInspired
