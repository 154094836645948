import React, { useLayoutEffect } from "react"

import logo from "assets/img/general/logo.png"

const Loader = () => {
  useLayoutEffect(() => {
    let perfData = window.performance.timing
    let EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart)
    let time = parseInt((EstimatedTime / 1000) % 60) * 100
    let start = 0
    let end = 100
    let duration = time

    /** Animate count. */
    let range = end - start
    let current = start
    let increment = end > start ? 1 : -1
    let stepTime = Math.abs(Math.floor(duration / range))

    if (stepTime <= 35) stepTime = 40
    // console.log(stepTime)

    let timer = setInterval(function () {
      current += increment

      let element = document.querySelector("#main-preloader .count")
      element.innerHTML = current + "%"

      if (current === end) {
        clearInterval(timer)

        document.querySelector("#main-preloader").classList.add("remove")
        setTimeout(
          () =>
            document
              .querySelector("main.page")
              .classList.add("transition-enter-done"),
          1500
        )
      }
    }, stepTime)
  }, [])

  return (
    <div id="main-preloader">
      <div className="background-1"></div>
      <div className="background-2"></div>

      <div className="logo">
        <img src={logo} alt="Imagem - Suvinil" />
      </div>

      <div className="count">0%</div>
    </div>
  )
}

export default Loader
