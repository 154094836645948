import React from "react"

import Left from "./Left"
import Center from "./Center"
import Right from "./Right"

const SectionHero = () => {
  return (
    <section className="section-hero">
      <div className="background" style={{ backgroundColor: "#4089F8" }}></div>

      <div className="container">
        <div className="flex-wrapper">
          <Left />
          <Center />
          <Right />
        </div>
      </div>
    </section>
  )
}

export default SectionHero
