const theme = {
  colors: {
    defaultColor: "#F66602",
    white: "#fff",
    gray: "#f2f2f2",
    SoftGray: "#E5E5E5",
    borderGray: "#dedede",
    darkGray: "#606060",
    defaultInput: "#f2f2f2",
    defaultHoverButton: "#e25d00",
    defaultHoverTable: "#ff690080",
    defaultBoxShadow: "rgba(0, 0, 0, 0.1)",
    error: "#f02000",
    lightError: "#ffe6e2",
  },
  font: {
    fontSizeDefault: "14px",
  },
  validations: {
    imageRequired: "Imagem é obrigatória.",
    fieldRequired: "Campo é obrigatório.",
  },
};

export default theme;
