import React, { useEffect } from "react"

import "./style.scss"

const Mobile = () => {
  useEffect(() => {
    window.onload = function () {
      const width = window.innerWidth

      if (width <= 1220) {
        document.querySelector(".not-responsive").classList.add("active")
      }
    }

    window.addEventListener("resize", function () {
      const width = window.innerWidth

      if (width <= 1220) {
        document.querySelector(".not-responsive").classList.add("active")
      } else {
        document.querySelector(".not-responsive").classList.remove("active")
      }
    })
  }, [])

  return (
    <div className="not-responsive">
      <span>
        Esse site não está otimizado
        <br />
        para telas menores.
      </span>
    </div>
  )
}

export default Mobile
