import React from "react"
import Header from "../../components/core/layout/Header"
import SectionHero from "../../components/presentation/Profile/SectionHero"
import SectionAccount from "../../components/presentation/Profile/SectionAccount"
import SectionProducts from "../../components/presentation/Profile/SectionProducts"
import Footer from "../../components/core/layout/Footer"

import "./profile.scss"

const Profile = () => (
  <main id="page-profile" className="page">
    <Header />
    <SectionHero />
    <SectionAccount />
    <SectionProducts />
    <Footer newsletterDisabled={true} />
  </main>
)

export default Profile
