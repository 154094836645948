import React from "react"

import imageCircle from "assets/img/product/circle-product.svg"
import imageProduct from "assets/img/product/product-image.png"
import {
  productArrowLeft,
  productArrowRight,
} from "assets/vectors/product/general"

const Center = () => {
  return (
    <div className="center">
      <div className="center-circle">
        <img src={imageCircle} alt="Imagem - Suvinil" />
      </div>

      <div className="center-product">
        <img src={imageProduct} alt="Imagem - Suvinil" />
      </div>

      <div className="center-navigation">
        <div className="center-navigation--arrow">
          <button
            type="button"
            title="Voltar animação"
            aria-label="Voltar animação"
          >
            {productArrowLeft}
          </button>
        </div>

        <div className="center-navigation--circle"></div>

        <div className="center-navigation--arrow">
          <button
            type="button"
            title="Avançar animação"
            aria-label="Avançar animação"
          >
            {productArrowRight}
          </button>
        </div>
      </div>

      <div className="center-text">
        <strong>Simule aqui</strong> para experimentar essa tinta
      </div>
    </div>
  )
}

export default Center
