import React from "react"

const Step8 = ({ tab, setTab, step, setStep, setFinished }) => {
  const items = [
    {
      title: "Eu mesmo",
    },
    {
      title: "Um amigo ou parente",
    },
    {
      title: "Um pintor",
    },
  ]

  return (
    <div
      className={tab === 8 ? "tab-content active" : "tab-content"}
      data-index="7"
    >
      <div className="flex-wrapper">
        <div className="left">
          <div className="left-title">
            <div className="line">
              <span data-index="1">E</span>
              <span data-index="2">por</span>
              <span data-index="3">último,</span>
              <span data-index="4">quem</span>
            </div>

            <div className="line">
              <span data-index="5">irá</span>
              <span data-index="6">pintar?</span>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="right-list">
            {items.map((item, index) => (
              <button
                type="button"
                className={step === item.title ? "active" : ""}
                key={`multi-step-tab-8-item-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
                onClick={() => {
                  setStep(item.title)
                  setFinished(true)
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step8
