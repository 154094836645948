import React from "react"

const Step3 = ({ tab, setTab, step, setStep }) => {
  const items = [
    {
      color: "#FFFFFF",
      title: "Branco",
    },
    {
      color: "#EDE9DB",
      title: "Tons suaves",
    },
    {
      color: "#FBCB22",
      title: "Amarelo",
    },
    {
      color: "#F58C38",
      title: "Laranja",
    },
    {
      color: "#CE2121",
      title: "Vermelho",
    },
    {
      color: "#E89BC0",
      title: "Rosa",
    },
    {
      color: "#AF7EE0",
      title: "Roxo",
    },
    {
      color: "#59A5CF",
      title: "Azul",
    },
    {
      color: "#65C638",
      title: "Verde",
    },
    {
      color: "#85614D",
      title: "Marrom",
    },
    {
      color: "#CCCCCC",
      title: "Cinza",
    },
  ]

  return (
    <div
      className={tab === 3 ? "tab-content active" : "tab-content"}
      data-index="2"
    >
      <div className="flex-wrapper">
        <div className="left">
          <div className="left-title">
            <div className="line">
              <span data-index="1">Qual</span>
              <span data-index="2">é</span>
              <span data-index="3">a</span>
              <span data-index="4">cor</span>
            </div>

            <div className="line">
              <span data-index="5">predominante</span>
            </div>

            <div className="line">
              <span data-index="6">no</span>
              <span data-index="7">cômodo?</span>
            </div>
          </div>

          <div className="left-description">
            <p>
              Aqui entra cor de móveis e de paredes que não serão pintadas agora
            </p>
          </div>
        </div>

        <div className="right">
          <div className="right-colors">
            {items.map((item, index) => (
              <div
                className={step === item.title ? "item active" : "item"}
                key={`multi-step-tab-3-item-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
                onClick={() =>
                  step === item.title ? setTab(tab + 1) : setStep(item.title)
                }
              >
                <div
                  className="item-block"
                  style={{ backgroundColor: item.color }}
                ></div>
                <div className="item-title">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step3
