import styled from "styled-components"
import theme from "theme/"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: 7.5px;
  margin-bottom: ${({ mb }) => (mb ? mb : "15px")};

  > svg {
    position: absolute;
    right: 10px;
    font-size: 20px;
  }
`

export const Label = styled.label`
  color: ${theme.colors.darkGray};
  font-weight: 700;
  font-size: ${theme.font.fontSizeDefault};

  ${({ isError }) => isError && `color: ${theme.colors.error}`}
`

export const InputStyle = styled.input`
  margin: 0;
  outline: 0;
  line-height: 1.21em;
  padding: 0.67em 1em;
  font-size: 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 5px;
  background-color:${({ bg }) => (bg ? bg : "")}
  font-size: 14px;
  box-shadow: inset 0 0 0 0 transparent;
  transition: color 0.1s ease, border-color 0.1s ease;
  height: 45px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : "")};
  width: ${({ width }) => width}
    ${({ isError }) =>
      isError &&
      `border-color: ${theme.colors.error}; background-color: ${theme.colors.lightError};`};
`
