import React, { useState } from "react"

import image from "assets/img/home/hero-slide.jpg"
import { arrowLeft, arrowRight } from "assets/vectors/general/arrows"

const SectionSlide = () => {
  const [slide, setSlide] = useState(0)
  const slides = [
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
  ]

  return (
    <section className="section-slide">
      <div className="slide">
        <div className="slide-items">
          {slides.map((item, index) => (
            <div
              className={slide === index ? "item active" : "item"}
              key={`home-hero-slide-${index}`}
            >
              <div className="item-image">
                <img src={item.image} alt="Imagem - Suvinil" />
              </div>
            </div>
          ))}
        </div>

        <div className="slide-navigation">
          <div className="slide-arrow slide-arrow--left">
            <button
              type="button"
              title="Voltar slide"
              aria-label="Voltar slide"
              onClick={() => setSlide(slide === 0 ? 2 : slide - 1)}
            >
              {arrowLeft}
            </button>
          </div>

          <div className="slide-dots">
            {slides.map((item, index) => (
              <div
                className={slide === index ? "dot active" : "dot"}
                key={`home-hero-slide-dot-${index}`}
                onClick={() => setSlide(index)}
              >
                <button
                  type="button"
                  title="Ir para o slide"
                  aria-label="Ir para o slide"
                >
                  {index}
                </button>
              </div>
            ))}
          </div>

          <div className="slide-arrow slide-arrow--right">
            <button
              type="button"
              title="Avançar slide"
              aria-label="Avançar slide"
              onClick={() => setSlide(slide === 2 ? 0 : slide + 1)}
            >
              {arrowRight}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSlide
