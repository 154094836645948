import React, { useState, useEffect } from "react"

import { Redirect } from "react-router-dom"
import Cart from "./Cart"
import Button from "components/core/Button"

const SectionCart = () => {
  const [checkout, setCheckout] = useState(false)
  const [productCount1, setProductCount1] = useState(1)
  const [productCount2, setProductCount2] = useState(1)
  const [productCount3, setProductCount3] = useState(1)
  const [freight, setFreight] = useState(false)
  const [totalPrice, setTotalPrice] = useState()

  useEffect(() => {
    const price1 = productCount1 * 125.9
    const price2 = productCount2 * 125.9
    const price3 = productCount3 * 27.5
    const total = price1 + price2 + price3 + (freight ? freight : 0)

    setTotalPrice(total.toFixed(2))
  }, [productCount1, productCount2, productCount3, freight])

  const buy = () => {
    const page = document.querySelector("#page-cart")
    const modal = page.querySelector(".modal-pay")

    window.scrollTo(0, 0)
    setTimeout(() => modal.classList.add("active"), 200)
    setTimeout(() => setCheckout(true), 6700)
  }

  return (
    <section className="section-cart">
      {checkout ? <Redirect to="/checkout" /> : ""}

      <div className="container">
        <div className="headline">
          <h2 className="headline-title">Meu carrinho</h2>
        </div>

        <div className="flex-wrapper">
          <div className="left">
            <div className="left-header">
              <div className="left-header--item">Produto</div>
              <div className="left-header--item">Quantidade</div>
              <div className="left-header--item">Entrega</div>
              <div className="left-header--item">Preço</div>
            </div>

            <Cart
              productCount1={productCount1}
              setProductCount1={setProductCount1}
              productCount2={productCount2}
              setProductCount2={setProductCount2}
              productCount3={productCount3}
              setProductCount3={setProductCount3}
              freight={freight}
            />

            <div className="left-freight">
              <div className="left-freight--header">
                <span>Calcular frete e prazo</span>

                <div className="freight">
                  <input type="text" placeholder="Insira o seu CEP" />
                  <Button>Verificar</Button>
                </div>

                <label>Não sei meu cep</label>
              </div>

              <div className="left-freight--content">
                <form>
                  <div className="field-group">
                    <input type="radio" name="freight" id="freight-1" />
                    <label htmlFor="freight-1" onClick={() => setFreight(24.6)}>
                      <strong>Correios</strong> • Receber entre os dias 02 de
                      Julho e 10 de Julho • R$24,60
                    </label>
                  </div>

                  <div className="field-group">
                    <input type="radio" name="freight" id="freight-2" />
                    <label htmlFor="freight-2" onClick={() => setFreight(0)}>
                      <strong>Retirar na loja</strong> • Entre os dias 27 de
                      Julho e 10 de Agosto • Grátis
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="right-title">Resumo do pedido</div>

            <div className="right-item">
              <span>
                <strong>3</strong> Produtos
              </span>
              <span>
                <strong>
                  {(
                    productCount1 * 125.9 +
                    productCount2 * 125.9 +
                    productCount3 * 27.5
                  )
                    .toFixed(2)
                    .replace(".", ",")}
                </strong>
              </span>
            </div>

            <div className="right-freight">
              <span>Frete</span>
              <span>
                {freight !== false ? (
                  <strong>
                    {freight === 0
                      ? "Grátis"
                      : freight.toFixed(2).replace(".", ",")}
                  </strong>
                ) : (
                  <strong>A definir</strong>
                )}
              </span>
            </div>

            <div className="right-total">
              <span>Total</span>
              <span>
                <strong>{(totalPrice + "").replace(".", ",")}</strong>
              </span>
            </div>

            <div className="right-text">
              <span>
                em até 10x de R${" "}
                {(totalPrice / 10).toFixed(2).replace(".", ",")}
              </span>
            </div>

            <div
              className={freight !== false ? "right-cta" : "right-cta disabled"}
              onClick={() => buy()}
            >
              <Button>Continuar</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionCart
