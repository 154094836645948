import React from "react"

import Pagination from "components/core/Pagination"

import { arrowRight } from "assets/vectors/general/arrows"
import productImage1 from "assets/img/general/product-image-1.png"
import productImage2 from "assets/img/general/product-image-2.png"
import serviceImage from "assets/img/profile/order-service.png"

const Orders = () => {
  const products = [
    {
      image: productImage1,
      number: "345690",
      title: "Tinta Suvinil Fosco Completo",
      color: "Cair da noite",
      parcela: "1x de R$ 144,90",
      vendedor: "Varejão das Tintas",
      status: "Enviado",
      data: "Data do pedido: 24/06/2020",
      limit: "Chega até dia 08 de julho",
    },
    {
      image: productImage2,
      number: "85649",
      title: "Tinta Suvinil Clássica",
      color: "Mantra",
      parcela: "1x de R$ 158,99",
      vendedor: "Varejão das Tintas",
      status: "Entregue",
      data: "Data do pedido: 03/01/2020",
      limit: "Entregue dia 15/01/2020",
    },
    {
      image: productImage1,
      number: "85650",
      title: "Tinta Suvinil Fosco Completo",
      color: "Cair da noite",
      parcela: "1x de R$ 144,90",
      vendedor: "Varejão das Tintas",
      status: "Entregue",
      data: "Data do pedido: 05/01/2019",
      limit: "Entregue dia 15/01/2019",
    },
  ]

  return (
    <div className="orders">
      <div className="orders-title">Meus pedidos</div>

      <div className="orders-listing">
        {products.map((item, index) => (
          <div className="item" key={`orders-product-item-${index}`}>
            <div className="item-header">
              <span>
                Número do pedido: <strong>{item.number}</strong>
              </span>
              <span>Data do pedido: 03/01/2020 {item.date}</span>
              <button
                type="button"
                title="Ver detalhes"
                aria-label="Ver detalhes"
              >
                Ver detalhes
              </button>
            </div>

            <div className="item-bottom">
              <div className="item-bottom--image">
                <img src={item.image} alt="Imagem- Suvinil" />
              </div>

              <div className="item-bottom--content">
                <span>Produto</span>
                <span>{item.title}</span>
                <span>Cor</span>
                <span>{item.color}</span>
                <span>{item.parcela}</span>
              </div>

              <div className="item-bottom--vendedor">
                <span>Vendedor</span>
                <span>{item.vendedor}</span>
              </div>

              <div className="item-bottom--status">
                <span>Status</span>
                <span>{item.status}</span>
                <span>{item.limit}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="orders-pagination">
        <Pagination />
      </div>

      <div className="orders-title">Serviços contratados</div>

      <div className="orders-listing">
        <div className="item">
          <div className="item-header">
            <span>
              Número do pedido: <strong>345690</strong>
            </span>
            <span>Data do pedido: 24/06/2020</span>
            <button
              type="button"
              title="Ver detalhes"
              aria-label="Ver detalhes"
            >
              Ver detalhes
            </button>
          </div>

          <div className="item-bottom style-2">
            <div className="item-bottom--image style-2">
              <img src={serviceImage} alt="Imagem- Suvinil" />
            </div>

            <div className="item-bottom--content">
              <span>Serviço</span>
              <span>Consultoria de cores</span>
              <span>Qtd. de ambientes</span>
              <span>1</span>
              <span>1x de R$ 179,90</span>
            </div>

            <div className="item-bottom--status">
              <span>Status</span>
              <span>Liberado da plataforma digital</span>

              <button type="button" title="Acessar" aria-label="Acessar">
                Acessar
                {arrowRight}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Orders
