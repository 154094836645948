import styled from "styled-components"

export const Header = styled.div`
  width: 100%;
  height: 80px;
  background-color: #04456b;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  border-radius: 8px 8px 0 0;

  span {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
  }
`

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  padding: 40px;
  margin-top: 16px;
  width: 460px;
`

export const FormItems = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ImgBg = styled.img`
  width: 100%;
  cursor: pointer;
`

export const SocialButton = styled.div`
  width: 312px;
  box-shadow: 1px black;
  height: 56px;
  box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.15);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Suvinil Sans;
  padding-left: 26px;
  img {
    margin-right: 22px;
  }
  :hover {
    cursor: pointer;
  }
`

export const FormBody = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

  input {
    height: 48px;
    color: #333333;
    font-size: 14px;
    padding: 0 18px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    outline: none;

    &::placeholder {
      color: #333333;
    }
  }

  button {
    margin-top: 25px;
  }
`

export const ExitIcon = styled.img`
  width: 15.08px;
  height: 15.08px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
`

export const CreateAcount = styled.div`
  width: 385px;
  height: 54px;
  margin: 35px auto;
  background: #f7f7f7;
  border-radius: 8px;
  text-align: center;
  font-family: Suvinil Sans;
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.05em;

  span {
    color: #f58c38;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    letter-spacing: -0.05em;
    cursor: pointer;
    margin-bottom: 0px;
    transition: color 0.5s;

    &:hover {
      color: #333333;
    }
  }
`
