import React from "react"
import { Link } from "react-router-dom"
import urls from "config/constants/urls"

import { arrowRight } from "assets/vectors/general/arrows"
import blocksImage1 from "assets/img/home/blocks-image-1.jpg"
import blocksImage2 from "assets/img/home/blocks-image-2.jpg"
import blocksImage3 from "assets/img/home/blocks-image-3.jpg"
import blocksIcon1 from "assets/img/home/blocks-icon-1.png"
import blocksIcon2 from "assets/img/home/blocks-icon-2.png"
import blocksIcon3 from "assets/img/home/blocks-icon-3.png"
import blocksIcon4 from "assets/img/home/blocks-icon-4.png"

const SectionBlocks = () => {
  const blocks1 = [
    {
      image: blocksImage1,
      title: "Multistep",
      description:
        "Separamos ambientes incríveis\npara você expandir sua criatividade.",
      link: urls.LINKS.MULTI_STEP,
    },
    {
      image: blocksImage2,
      title: "Simulador",
      description:
        "Separamos ambientes incríveis\npara você expandir sua criatividade.",
    },
    {
      image: blocksImage3,
      title: "Inspire-se",
      description:
        "Separamos ambientes incríveis\npara você expandir sua criatividade.",
      link: urls.LINKS.GET_INSPIRED,
    },
  ]

  const blocks2 = [
    {
      icon: blocksIcon1,
      title: "Facilidade de compra",
      description: "Pagamentos em até\n6x sem juros",
    },
    {
      icon: blocksIcon2,
      title: "Do seu jeito",
      description: "Compre online e\nretire na loja",
    },
    {
      icon: blocksIcon3,
      title: "Pra renovar sempre",
      description: "Todos os produtos Suvinil\nem um só lugar",
    },
    {
      icon: blocksIcon4,
      title: "Facilidade de compra",
      description: "Mais de 1.700 cores\nno leque",
    },
  ]

  return (
    <section className="section-blocks">
      <div className="container">
        <div className="flex-wrapper">
          {blocks1.map((item, index) => (
            <div className="item" key={`home-items-item-${index}`}>
              {item.link ? (
                <Link
                  to={item.link}
                  className="item-link"
                  title="Ir para a página"
                  aria-label="Ir para a página"
                >
                  Ir para a página
                </Link>
              ) : (
                <button
                  type="button"
                  className="item-link"
                  title="Abrir modal"
                  aria-label="Abrir modal"
                  onClick={() =>
                    document
                      .querySelector(".modal-simulator")
                      .classList.add("active")
                  }
                >
                  Abrir modal
                </button>
              )}

              <div className="item-image">
                <img src={item.image} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <h3 className="item-content--title">{item.title}</h3>

                <div className="item-content--description">
                  <p>{item.description}</p>
                </div>
              </div>

              <div className="item-arrow">{arrowRight}</div>
            </div>
          ))}
        </div>

        <div className="flex-wrapper">
          {blocks2.map((item, index) => (
            <div className="block" key={`home-blocks-block-${index}`}>
              <div className="block-icon">
                <img src={item.icon} alt="Imagem - Suvinil" />
              </div>

              <div className="block-content">
                <h3 className="block-content--title">{item.title}</h3>

                <div className="block-content--description">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionBlocks
