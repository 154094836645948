import React from "react"

import image from "assets/img/multi-step/hero-background.jpg"

const SectionHero = () => (
  <section className="section-hero">
    <div className="background">
      <img src={image} alt="Imagem - Suvinil" />
    </div>

    <div className="wrapper">
      <div className="container">
        <div className="content">
          <h1 className="content-title">Encontre a sua cor ideal</h1>
        </div>
      </div>
    </div>
  </section>
)

export default SectionHero
