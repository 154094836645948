import React from "react"

import iconVerified from "assets/img/general/verified.svg"
import cardImage1 from "assets/img/get-inspired/cards-image-1.jpg"
import cardImage2 from "assets/img/get-inspired/cards-image-6.jpg"
import cardImage3 from "assets/img/get-inspired/cards-image-3.jpg"

const SectionEnvironments = () => {
  const cards = [
    {
      client: true,
      image: cardImage1,
    },
    {
      client: true,
      image: cardImage2,
    },
    {
      client: true,
      image: cardImage3,
    },
  ]

  return (
    <section className="section-environments">
      <div className="container">
        <div className="headline">
          <h2 className="headline-title">Ambientes com essa cor de tinta</h2>

          <div className="headline-description">
            <p>
              Trouxemos fotos de ambientes enviados por clientes que usaram essa
              mesma tinta para você se inspirar
            </p>
          </div>
        </div>

        <div className="flex-wrapper">
          {cards.map((item, index) => (
            <div className="item" key={`environments-item-${index}`}>
              <div className="item-image">
                <img src={item.image} alt="Imagem - Suvinil" />
              </div>

              {item.client ? (
                <div className="item-content">
                  <img src={iconVerified} alt="Imagem - Suvinil" />
                  <span>Ambiente de cliente</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionEnvironments
