import React from "react";
import { Modal } from "react-bootstrap";

import { Container, ContentClose } from "./ModalStyle";

const ContainerModal = ({
  className,
  show,
  handleClose,
  buttonClose,
  children,
  size,
}) => {
  return (
    <Container>
      <Modal
        style={{ borderRadius: "10px" }}
        size={size || "lg"}
        className={className}
        show={show}
        onHide={handleClose}
      >
        <Modal.Body style={{ padding: 0 }}>
          {buttonClose && (
            <ContentClose>
              <button type="button" class="close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
              </button>
            </ContentClose>
          )}
          {children}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ContainerModal;
