import React, { useState } from "react"

import Orders from "./Orders"
import Data from "./Data"

const SectionAccount = () => {
  const [tab, setTab] = useState(1)

  return (
    <section className="section-account">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-navigation">
              <ul>
                <li className={tab === 1 ? "active" : ""}>
                  <button
                    type="button"
                    title="Ver conteúdo"
                    aria-label="Ver conteúdo"
                    onClick={() => setTab(1)}
                  >
                    Meus pedidos
                  </button>
                </li>

                <li className={tab === 2 ? "active" : ""}>
                  <button
                    type="button"
                    title="Ver conteúdo"
                    aria-label="Ver conteúdo"
                    onClick={() => setTab(2)}
                  >
                    Dados pessoais
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className={tab === 1 ? "right style-1" : "right style-2"}>
            <div
              className={tab === 1 ? "right-tab active" : "right-tab"}
              data-index="0"
            >
              <Orders />
            </div>

            <div
              className={tab === 2 ? "right-tab active" : "right-tab"}
              data-index="1"
            >
              <Data />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAccount
