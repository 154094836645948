import React from "react"

import image from "assets/img/checkout/hero-background.jpg"

const SectionHero = () => (
  <section className="section-hero">
    <div className="background">
      <img src={image} alt="Imagem - Suvinil" />
    </div>

    <div className="wrapper">
      <div className="container">
        <div className="content">
          <h1 className="content-title">Parabéns!</h1>

          <div className="content-description">
            <p>A sua compra foi concluída com sucesso.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionHero
