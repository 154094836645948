import React from "react"

import CardProduct from "components/core/CardProduct"
import Pagination from "components/core/Pagination"
import Button from "components/core/Button"

import { arrowRight } from "assets/vectors/general/arrows"
import iconVerified from "assets/img/general/verified.svg"
import iconPlay from "assets/img/general/play.svg"
import { ReactComponent as IconCompare } from "assets/img/products/product-compare.svg"
import locationMarker from "assets/img/general/location-marker.png"
import productImage1 from "assets/img/general/product-image-1.png"
import productImage2 from "assets/img/general/product-image-2.png"
import videoImage1 from "assets/img/general/videos-image-1.jpg"
import videoImage2 from "assets/img/general/videos-image-2.jpg"

const Products = () => {
  const products = [
    {
      headlineBackground: "C39446",
      headlineColor: "FFFFFF",
      headlineTitle: "Quentão",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "7D0606",
      headlineColor: "FFFFFF",
      headlineTitle: "Rubi",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "DCE4D5",
      headlineColor: "333333",
      headlineTitle: "Limonada Refrescante",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "E2AC8D",
      headlineColor: "333333",
      headlineTitle: "Vela aromatizante",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "EDC4C4",
      headlineColor: "333333",
      headlineTitle: "Margaridinha-rosa",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "7D0606",
      headlineColor: "FFFFFF",
      headlineTitle: "Rubi",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "DCE4D5",
      headlineColor: "333333",
      headlineTitle: "Limonada Refrescante",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "E2AC8D",
      headlineColor: "333333",
      headlineTitle: "Vela aromatizante",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
  ]

  const videos = [
    {
      official: true,
      image: videoImage1,
      title: "Como fazer marmorato",
      time: "4m20s",
    },
    {
      official: false,
      image: videoImage2,
      title: "Criando textura na parede",
      time: "15m33s",
    },
  ]

  return (
    <div className="listing">
      <div className="listing-header">
        <div classNam="left">
          <div className="listing-header--title">
            Resultados baseados no seu interesse
          </div>

          <div className="listing-header--button">
            <button type="button" title="Alterar cep" aria-label="Alterar cep">
              <img src={locationMarker} alt="Imagem - Suvinil" />

              <span>
                <strong>Clique aqui</strong> e informe o seu CEP para visualizar
                os preços oficiais para a sua localidade
              </span>
            </button>
          </div>
        </div>

        <div className="right">
          <Button
            onClick={() =>
              document.querySelector(".modal-compare").classList.add("active")
            }
          >
            <IconCompare />
            Compare
          </Button>
        </div>
      </div>

      <div className="listing-items">
        <div className="grid-wrapper">
          {products.map((product, index) => (
            <CardProduct
              headlineBackground={product.headlineBackground}
              headlineColor={product.headlineColor}
              headlineTitle={product.headlineTitle}
              image={product.image}
              title={product.title}
              price={product.price}
              installment={product.installment}
              key={`product-listing-1-${index}`}
            />
          ))}
        </div>

        <div className="block">
          <div className="block-content">
            <div className="block-content--description">
              <p>
                Tutorias
                <br />
                exclusivos de
                <br />
                especialistas
                <br />
                para te ajudar
                <br />
                na jornada
              </p>
            </div>

            <div className="block-content--cta">
              <a
                href="#provisorio"
                title="Ir para a página"
                aria-label="Ir para a página"
              >
                <span>Veja as dicas</span>
                {arrowRight}
              </a>
            </div>
          </div>

          <div className="block-items">
            {videos.map((item, index) => (
              <div className="item" key={`videos-item-${index}`}>
                <div className="item-block">
                  <a
                    href="#provisorio"
                    className="item-block--link"
                    title="Ver vídeo"
                    aria-label="Ver vídeo"
                  >
                    Ver vídeo
                  </a>

                  {item.official ? (
                    <div className="item-block--verified">
                      <img src={iconVerified} alt="Imagem - Suvinil" />
                      <span>Tutorial oficial</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="item-block--image">
                    <img src={item.image} alt="Imagem - Suvinil" />
                  </div>

                  <div className="item-block--play">
                    <img src={iconPlay} alt="Imagem - Suvinil" />
                  </div>

                  <div className="item-block--title">{item.title}</div>
                  <div className="item-block--time">{item.time}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="grid-wrapper">
          {products.map((product, index) => (
            <CardProduct
              headlineBackground={product.headlineBackground}
              headlineColor={product.headlineColor}
              headlineTitle={product.headlineTitle}
              image={product.image}
              title={product.title}
              price={product.price}
              installment={product.installment}
              key={`product-listing-2-${index}`}
            />
          ))}
        </div>
      </div>

      <div className="listing-pagionation">
        <Pagination />
      </div>
    </div>
  )
}

export default Products
