import React from "react"

import iconVerified from "assets/img/general/verified.svg"
import iconPlay from "assets/img/general/play.svg"
import iconListStyle from "assets/img/general/list-style.svg"
import image1 from "assets/img/general/videos-image-1.jpg"
import image2 from "assets/img/general/videos-image-2.jpg"
import image3 from "assets/img/general/videos-image-3.jpg"

import "./style.scss"

const SectionVideos = () => {
  const slides = [
    {
      official: true,
      image: image1,
      title: "Como fazer marmorato",
      time: "4m20s",
    },
    {
      official: false,
      image: image2,
      title: "Criando textura na parede",
      time: "15m33s",
    },
    {
      official: false,
      image: image3,
      title: "Como usar massa corrida",
      time: "7m43s",
    },
  ]

  return (
    <section className="section-videos">
      <div className="container">
        <div className="headline">
          <h2 className="headline-title">Vídeos tutoriais</h2>

          <div className="headline-description">
            <p>Aprenda técnicas incríveis com nossos especialistas</p>
          </div>
        </div>

        <div className="flex-wrapper">
          {slides.map((item, index) => (
            <div className="item" key={`videos-item-${index}`}>
              <div className="item-block">
                <a
                  href="#provisorio"
                  className="item-block--link"
                  title="Ver vídeo"
                  aria-label="Ver vídeo"
                >
                  Ver vídeo
                </a>

                {item.official ? (
                  <div className="item-block--verified">
                    <img src={iconVerified} alt="Imagem - Suvinil" />
                    <span>Tutorial oficial</span>
                  </div>
                ) : (
                  ""
                )}

                <div className="item-block--image">
                  <img src={item.image} alt="Imagem - Suvinil" />
                </div>

                <div className="item-block--play">
                  <img src={iconPlay} alt="Imagem - Suvinil" />
                </div>

                <div className="item-block--title">{item.title}</div>
                <div className="item-block--time">{item.time}</div>
              </div>

              <div className="item-content">
                <h3 className="item-content--title">Você vai precisar de:</h3>

                <div className="item-content--list">
                  <div className="item-list">
                    <div className="item-list--icon">
                      <img src={iconListStyle} alt="Imagem - Suvinil" />
                    </div>

                    <div className="item-list--title">
                      Tinta Suvinil Marmorato Fosco
                    </div>

                    <div className="item-list--cta">
                      <a
                        href="#provisorio"
                        title="Ir para a página"
                        aria-label="Ir para a página"
                      >
                        Comprar
                      </a>
                    </div>
                  </div>

                  <div className="item-list">
                    <div className="item-list--icon">
                      <img src={iconListStyle} alt="Imagem - Suvinil" />
                    </div>

                    <div className="item-list--title">Cera incolor</div>

                    <div className="item-list--cta">
                      <a
                        href="#provisorio"
                        title="Ir para a página"
                        aria-label="Ir para a página"
                      >
                        Comprar
                      </a>
                    </div>
                  </div>

                  <div className="item-list">
                    <div className="item-list--icon">
                      <img src={iconListStyle} alt="Imagem - Suvinil" />
                    </div>

                    <div className="item-list--title">Desempenadeira</div>

                    <div className="item-list--cta">
                      <a
                        href="#provisorio"
                        title="Ir para a página"
                        aria-label="Ir para a página"
                      >
                        Comprar
                      </a>
                    </div>
                  </div>

                  <div className="item-list">
                    <div className="item-list--icon">
                      <img src={iconListStyle} alt="Imagem - Suvinil" />
                    </div>

                    <div className="item-list--title">Espátula</div>

                    <div className="item-list--cta">
                      <a
                        href="#provisorio"
                        title="Ir para a página"
                        aria-label="Ir para a página"
                      >
                        Comprar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionVideos
