/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import urls from "config/constants/urls"
// import isAuthenticated from "utils/isAuthenticated";
import GetInspired from "../containers/GetInspired"
import Product from "../containers/Product"
import Products from "../containers/Products"
import MultiStep from "../containers/MultiStep"
import Home from "../containers/Home"
import Profile from "../containers/Profile"
import Cart from "../containers/Cart"
import Checkout from "../containers/Checkout"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Loader from "components/core/layout/Loader"
import Mobile from "components/core/layout/Mobile"
import ModalSimulator from "components/core/layout/ModalSimulator"
import ModalCompare from "components/core/layout/ModalCompare"
export const history = createBrowserHistory()

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{ pathname: urls.ROUTES.APP, state: { from: props.location } }}
//         />
//       )
//     }
//   />
// );
const Routes = () => (
  <BrowserRouter>
    <Mobile />
    <Loader />
    <ModalSimulator />
    <ModalCompare />

    <Route
      render={({ location }) => {
        return (
          <TransitionGroup className="inner-wrapper">
            <CSSTransition
              key={location.key}
              classNames="transition"
              timeout={500}
            >
              <Switch location={location}>
                <Route exact path={urls.ROUTES.APP.path} component={Home} />
                <Route
                  exact
                  path={urls.ROUTES.GET_INSPIRED.path}
                  component={GetInspired}
                />
                <Route
                  exact
                  path={urls.ROUTES.PRODUCT.path}
                  component={Product}
                />
                <Route
                  exact
                  path={urls.ROUTES.PRODUCTS.path}
                  component={Products}
                />
                <Route
                  exact
                  path={urls.ROUTES.MULTI_STEP.path}
                  component={MultiStep}
                />
                <Route path={urls.ROUTES.PROFILE.path} component={Profile} />
                <Route path={urls.ROUTES.CHECKOUT.path} component={Checkout} />
                <Route path={urls.ROUTES.CART.path} component={Cart} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )
      }}
    />
  </BrowserRouter>
)

export default Routes
