import React from "react"

import Content from "./ButtonStyle"

const Button = ({
  type,
  onClick,
  disabled,
  btnType = "primary",
  children,
  width,
  mb,
  height,
}) => (
  <Content
    type={type}
    btnType={btnType}
    onClick={onClick}
    disabled={disabled}
    width={width}
    height={height}
    mb={mb}
  >
    <span>{children}</span>
  </Content>
)

export default Button
