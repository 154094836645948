import React from "react"
import Cards from "./Cards"
import Button from "components/core/Button"

import arrowDown from "assets/img/general/footer-arrow-down.svg"

const SectionCards = () => (
  <section className="section-cards">
    <div className="container">
      <div className="filter">
        <div className="filter-left">
          <input
            type="text"
            placeholder="Que tipo de ambiente vocês está buscando?"
          />
        </div>

        <div className="filter-right">
          <div className="filter-right--label">Filtrar por</div>

          <button
            type="button"
            className="filter-right--group"
            title="Abrir opções"
            aria-label="Abrir opções"
          >
            <span>Produto</span>
            <img src={arrowDown} alt="Imagem - Suvinil" />
          </button>

          <button
            type="button"
            className="filter-right--group"
            title="Abrir opções"
            aria-label="Abrir opções"
          >
            <span>Ambiente</span>
            <img src={arrowDown} alt="Imagem - Suvinil" />
          </button>

          <button
            type="button"
            className="filter-right--group"
            title="Abrir opções"
            aria-label="Abrir opções"
          >
            <span>Cor</span>
            <img src={arrowDown} alt="Imagem - Suvinil" />
          </button>
        </div>
      </div>

      <Cards />

      <div className="more">
        <Button>Ver mais</Button>
      </div>
    </div>
  </section>
)

export default SectionCards
