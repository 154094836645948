import React, { useState } from "react"
import * as S from "./styles"
import Arrow from "../../../assets/icons/arrow.svg"
function Pagination({ pages }) {
  const [selectedPage, setSelectedPage] = useState(1)
  const setPagination = (page) => {
    setSelectedPage(page)
  }
  return (
    <S.PaginationBody>
      <S.PaginationItem fixed>
        <img src={Arrow} alt="Imagem - Suvinil" />
      </S.PaginationItem>
      {Array(pages || 3)
        .fill()
        .map((x, page) => {
          return (
            <S.PaginationItem
              selected={page + 1 === selectedPage}
              onClick={() => setPagination(page + 1)}
              key={`pagination-item-${page}`}
            >
              {page + 1}
            </S.PaginationItem>
          )
        })}
      <S.PaginationItem fixed last>
        <img src={Arrow} alt="Imagem - Suvinil" />
      </S.PaginationItem>
    </S.PaginationBody>
  )
}

export default Pagination
