import React from "react"

import { arrowRight } from "assets/vectors/general/arrows"
import image from "assets/img/get-inspired/consultancy-image.png"

const SectionConsultancy = () => (
  <section className="section-consultancy">
    <div className="container">
      <div className="block">
        <div className="block-image">
          <img src={image} alt="Imagem - Suvinil" />
        </div>

        <div className="block-content">
          <h2 className="block-content--title">Consultoria Ao Vivo</h2>

          <div className="block-content--description">
            <p>
              Já pensou em contar com os melhores profissionais
              <br />
              para te ajudar na decoração sem nem precisar sair de casa?
            </p>
          </div>

          <div className="block-content--cta">
            <a
              href="#provisorio"
              title="Ir para a página"
              aria-label="Ir para a página"
            >
              <span>Contrate agora</span>
              {arrowRight}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionConsultancy
