import React, { useState, useEffect } from "react"

import Button from "components/core/Button"

import { arrowRight } from "assets/vectors/general/arrows"
import background from "assets/img/general/modal-simulator-background.jpg"
import ExitIcon from "assets/icons/close.svg"
import nextSlide from "assets/img/general/modal-simulator-next-slide.png"
import iconPaint from "assets/img/general/modal-simulator-paint.svg"
import imageSlide1 from "assets/img/general/modal-simulator-slide-1.png"
import imageSlide2 from "assets/img/general/modal-simulator-slide-2.png"
import imageSlide3 from "assets/img/general/modal-simulator-slide-3.png"

import "./modal-simulator.scss"

const ModalSimulator = () => {
  const [slide, setSlide] = useState(1)
  const [slideColor, setSlideColor] = useState("#d9edfd")
  const sidebar = [
    {
      title: "Estilo",
      list: [
        "Moderno",
        "Tradicional",
        "Vintage",
        "Moderno 2",
        "Tradicional 2",
        "Vintage 2",
        "Moderno 3",
        "Tradicional 3",
        "Vintage 3",
      ],
    },
    {
      title: "Cores",
      list: [
        "Azul",
        "Amarelo",
        "Vermelho",
        "Verde",
        "Roxo",
        "Laranja",
        "Rosa",
        "Preto",
        "Branco",
      ],
    },
  ]
  const colors = [
    "#1F96F2",
    "#E4F2FD",
    "#BBDCFB",
    "#90CAF8",
    "#d9edfd",
    "#41A5F3",
    "#2097F3",
    "#1D88E6",
    "#1976D3",
    "#0E47A1",
    "#438AFE",
    "#2879FF",
    "#0091EA",
    "#80DEE8",
    "#25C6DA",
    "#00ACC2",
    "#63B0ED",
    "#CBE7EA",
    "#4E7493",
    "#5F9AC9",
    "#045EB8",
    "#81C7FF",
    "#1A63DA",
    "#4254F4",
    "#368BDA",
    "#66A3FF",
  ]

  useEffect(() => {
    const modal = document.querySelector(".modal-simulator")
    const sidebar = modal.querySelector(".left")
    const items = sidebar.querySelectorAll(".left-item--listing")

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const list = item.querySelector("ul")
      const listing = list.querySelectorAll("li")

      list.addEventListener("scroll", function () {
        const height = list.offsetHeight
        const scrollTop = list.scrollTop
        const scrollHeight = list.scrollHeight
        const scrollMax = scrollHeight - height

        if (scrollMax - scrollTop <= 7) {
          item.classList.add("scrolled")
        } else {
          item.classList.remove("scrolled")
        }
      })

      for (let y = 0; y < listing.length; y++) {
        const item_list = listing[y]
        const button = item_list.querySelector("button")

        button.addEventListener("click", function () {
          item_list.classList.contains("active")
            ? item_list.classList.remove("active")
            : item_list.classList.add("active")
        })
      }
    }
  }, [])

  return (
    <div className="modal-simulator">
      <div className="modal-simulator--wrapper">
        <div
          className="modal-simulator--header"
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className="modal-simulator--header_title">Simulador</div>

          <button
            type="button"
            className="modal-simulator--header_close"
            tile="Fechar modal"
            aria-label="Fechar modal"
            onClick={() =>
              document
                .querySelector(".modal-simulator")
                .classList.remove("active")
            }
          >
            <img src={ExitIcon} alt="Imagem - Suvinil" />
          </button>
        </div>

        <div className="modal-simulator--content">
          <div className="left">
            <div className="left-search">
              <input type="text" placeholder="Digita para filtrar" />
            </div>

            {sidebar.map((item, index) => (
              <div
                className="left-item"
                key={`modal-simulator-left-item-${index}`}
              >
                <div className="left-item--title">{item.title}</div>

                <div className="left-item--listing">
                  <ul>
                    {item.list.map((option, index2) => (
                      <li
                        key={`modal-simulator-left-item-${index}-list-${index2}`}
                      >
                        <button
                          type="button"
                          title="Escolher opção"
                          aria-label="Escolher opção"
                        >
                          {option}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}

            <div className="left-submit">
              <Button>Confirmar</Button>
            </div>
          </div>

          <div className="right">
            <div
              className="right-next"
              onClick={() => (slide === 3 ? setSlide(1) : setSlide(slide + 1))}
            >
              <img src={nextSlide} alt="Imagem - Suvinil" />
              <span>Próxima foto</span>
            </div>

            <div
              className="right-slide"
              style={{ backgroundColor: slideColor }}
            >
              <div
                className={
                  slide === 1 ? "right-slide--item active" : "right-slide--item"
                }
              >
                <img src={imageSlide1} alt="Imagem - Suvinil" />
              </div>

              <div
                className={
                  slide === 2 ? "right-slide--item active" : "right-slide--item"
                }
              >
                <img src={imageSlide2} alt="Imagem - Suvinil" />
              </div>

              <div
                className={
                  slide === 3 ? "right-slide--item active" : "right-slide--item"
                }
              >
                <img src={imageSlide3} alt="Imagem - Suvinil" />
              </div>
            </div>

            <div className="right-content">
              <div className="right-content--icon">
                <img src={iconPaint} alt="Imagem - Suvinil" />
              </div>

              <div className="right-content--description">
                <span>Escolha a cor para simular</span>
                <span>Clique em confirmar para alterar a cor no produto</span>
              </div>

              <div className="right-content--arrows">
                <button
                  type="button"
                  title="Ver tons mais claros"
                  aria-label="Ver tons mais claros"
                >
                  {arrowRight}
                  <span>Ver tons mais claros</span>
                </button>

                <button
                  type="button"
                  title="Ver tons mais escuros"
                  aria-label="Ver tons mais escuros"
                >
                  <span>Ver tons mais escuros</span>
                  {arrowRight}
                </button>
              </div>
            </div>

            <div className="right-listing">
              {colors.map((item, index) => (
                <div
                  className={
                    slideColor === item
                      ? "right-listing--item active"
                      : "right-listing--item"
                  }
                  style={{ backgroundColor: item }}
                  key={`modal-simulator-colors-item-${index}`}
                  onClick={() => setSlideColor(item)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalSimulator
