import styled from "styled-components"
import ModalHeader from "../../../assets/img/modal-login-header.png"

export const Header = styled.div`
  width: 100%;
  background-image: url(${ModalHeader});
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  background-size: cover;

  span {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 125px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  padding: 40px;
  width: 800px;
`

export const FormItems = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ImgBg = styled.img`
  width: 100%;
  cursor: pointer;
`

export const SocialButton = styled.div`
  box-shadow: 1px black;
  width: 310px;
  height: 56px;
  box-shadow: 0px 0px 12px rgba(102, 102, 102, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  font-family: Suvinil Sans;
  letter-spacing: -0.03em;
  margin-bottom: ${({ last }) => (last ? `0` : "16px")};
  cursor: pointer;
  transition: background-color 0.5s;

  span {
    width: 80px;
    text-align: center;
    flex-shrink: 0;
  }

  :hover {
    background-color: #f7f7f7;
  }
`

export const FormBody = styled.div`
  display: flex;
  flex-flow: column;

  label {
    text-align: center;
    font-family: Suvinil Sans;
    font-weight: normal;
    line-height: 13px;
    text-align: center;
    margin-top: 16px;
    font-size: 11px;
    color: #f58c38;
    cursor: pointer;
    position: relative;
    bottom: -10px;
    transition: color 0.5s;

    &:hover {
      color: #333333;
    }
  }

  input {
    height: 48px;
    color: #333333;
    font-size: 14px;
    padding: 0 18px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    outline: none;

    &::placeholder {
      color: #333333;
    }
  }
`

export const CreateAcount = styled.div`
  width: 100%;
  height: 54px;
  background: #f7f7f7;
  border-radius: 8px;
  text-align: center;
  font-family: Suvinil Sans;
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.05em;
  margin-top: 55px;

  label {
    color: #f58c38;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    letter-spacing: -0.05em;
    cursor: pointer;
    margin-bottom: 0px;
    transition: color 0.5s;

    &:hover {
      color: #333333;
    }
  }
`

export const Divisor = styled.div`
  border: 1px solid #ededed;
  height: 218px;
  position: absolute;
  margin-left: 46%;
`

export const ExitIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 15.08px;
  height: 15.08px;
  cursor: pointer;
`
