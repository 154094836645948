import React from "react"
import { Link } from "react-router-dom"
import urls from "config/constants/urls"
import Modal from "../../core/Modal"
import * as S from "./styles"
import FacebookIcon from "../../../assets/icons/facebook-icon.svg"
import GoogleIcon from "../../../assets/icons/google-icon.svg"
import SecretIcon from "../../../assets/icons/secret-icon.svg"
import ExitIcon from "../../../assets/icons/close.svg"
import FormInput from "../../../components/core/FormInput"
import Button from "../../../components/core/Button"

const AuthForm = ({ show, setShowModal, openCreate }) => {
  return (
    <Modal show={show}>
      <S.Header>
        <span>Bem-vindo(a) à Suvinil!</span>
        <S.ExitIcon onClick={() => setShowModal()} src={ExitIcon} />
      </S.Header>
      <S.Body>
        <S.FormItems>
          <div>
            <S.SocialButton>
              <span>
                <img alt="..." src={FacebookIcon} />
              </span>
              Entrar com facebook
            </S.SocialButton>
            <S.SocialButton>
              <span>
                <img alt="..." src={GoogleIcon} />
              </span>
              Entrar com Google
            </S.SocialButton>
            <S.SocialButton last>
              <span>
                <img alt="..." src={SecretIcon} />
              </span>
              Receber chave de acesso <br /> rápido por e-mail
            </S.SocialButton>
          </div>
          <S.Divisor />
          <S.FormBody className="lorem">
            <FormInput placeholder="E-mail" />
            <FormInput placeholder="Senha" />
            <Link to={urls.LINKS.PROFILE} onClick={() => setShowModal()}>
              <Button width="300">Entrar</Button>
            </Link>
            <label>Esqueci minha senha</label>
          </S.FormBody>
        </S.FormItems>
        <S.CreateAcount>
          Ainda não tem uma conta?
          <label onClick={() => openCreate()}>Cadastre-se aqui</label>
        </S.CreateAcount>
      </S.Body>
    </Modal>
  )
}

export default AuthForm
