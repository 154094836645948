import React, { useEffect } from "react"

import { arrowRight } from "assets/vectors/general/arrows"
import {
  size1,
  size2,
  favorite,
  selectArrowDown,
} from "assets/vectors/product/general"

const Left = () => {
  useEffect(() => {
    const page = document.querySelector("#page-product")
    const left = page.querySelector(".section-hero .left")
    const sizes = page.querySelectorAll(".left-sizes ul li")
    const favorite = left.querySelector(".left-select--favorite")

    favorite.querySelector("button").addEventListener("click", function () {
      favorite.classList.contains("active")
        ? favorite.classList.remove("active")
        : favorite.classList.add("active")
    })

    for (let i = 0; i < sizes.length; i++) {
      const size = sizes[i]
      const button = size.querySelector("button")

      button.addEventListener("click", function () {
        for (let y = 0; y < sizes.length; y++) {
          sizes[y].classList.remove("active")
        }

        size.classList.add("active")
      })
    }
  }, [])

  return (
    <div className="left">
      <div className="left-select">
        <div className="left-select--title">Selecione a cor:</div>

        <div className="left-select--favorite">
          <button
            type="button"
            rel="Adicionar nos favoritos"
            aria-label="Adicionar nos favoritos"
          >
            {favorite}
            {favorite}
          </button>
        </div>

        <div className="left-select--box">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="white"
          >
            <rect
              x="-0.5"
              y="0.5"
              width="19.5398"
              height="19.5398"
              transform="matrix(-1 0 0 1 25.9941 3.42334)"
            />
            <path d="M7.09625 23.6422L2.28224 11.1258L6.77532 8.55829" />
          </svg>

          <span>Azul Vibrante</span>

          {selectArrowDown}
        </div>
      </div>

      <h1 className="left-title">Tinta Suvinil Fosco Completo</h1>

      <div className="left-compare">
        <button
          type="button"
          title="Comparar produtos"
          aria-label="Comparar produtos"
        >
          Comparar tintas
        </button>
      </div>

      <div className="left-sizes">
        <div className="left-sizes--title">Escolha o tamanho ideal:</div>

        <ul>
          <li className="active">
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
            >
              {size1}
              <span>3,6l</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              title="Selecionar opção"
              aria-label="Selecionar opção"
            >
              {size2}
              <span>18l</span>
            </button>
          </li>
        </ul>
      </div>

      <div className="left-calc">
        <div className="left-calc--title">
          Não sabe quanto é<br />
          necessário para você?
        </div>

        <div className="left-calc--cta">
          <a
            href="#provisorio"
            title="Ir para a página"
            aria-label="Ir para a página"
          >
            <span>Calcule agora</span>
            {arrowRight}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Left
