import React from "react"

import icon from "assets/img/checkout/icon.png"
import videoImage1 from "assets/img/general/videos-image-1.jpg"
import iconPlay from "assets/img/general/play.svg"

const SectionCheckout = () => (
  <section className="section-checkout">
    <div className="container">
      <div className="block">
        <div className="block-content">
          <div className="block-content--title">
            <img src={icon} alt="Imagem - Suvinil" />
            <span>#013-321039018</span>
          </div>

          <div className="block-content--description">
            <p>
              Seu código de compra se encontra acima para caso precise tirar
              dúvidas pela nossa central de atendimento ou pelo chat, para saber
              informações sobre a entrega, futuras devoluções, entre outros.
            </p>
          </div>
        </div>

        <div className="block-right">
          <div className="block-right--title">
            Que tal uma dica pra te ajudar com o produto?
          </div>

          <div className="item-block">
            <a
              href="#provisorio"
              className="item-block--link"
              title="Ver vídeo"
              aria-label="Ver vídeo"
            >
              Ver vídeo
            </a>

            <div className="item-block--image">
              <img src={videoImage1} alt="Imagem - Suvinil" />
            </div>

            <div className="item-block--play">
              <img src={iconPlay} alt="Imagem - Suvinil" />
            </div>

            <div className="item-block--title">Criando textura na parede</div>
            <div className="item-block--time">15m33s</div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionCheckout
