import React from "react"

import image1 from "assets/img/multi-step/step-5-image-1.png"
import image2 from "assets/img/multi-step/step-5-image-2.png"
import image3 from "assets/img/multi-step/step-5-image-3.png"
import image4 from "assets/img/multi-step/step-5-image-4.png"
import image5 from "assets/img/multi-step/step-5-image-5.png"
import image6 from "assets/img/multi-step/step-5-image-6.png"
import image7 from "assets/img/multi-step/step-5-image-7.png"

const Step5 = ({ tab, setTab, step, setStep }) => {
  const items = [
    {
      image: image1,
      title: "Parede",
    },
    {
      image: image2,
      title: "Madeira",
    },
    {
      image: image3,
      title: "Metal",
    },
    {
      image: image4,
      title: "Gesso",
    },
    {
      image: image5,
      title: "Pisos",
    },
    {
      image: image6,
      title: "Telhas, pedras e tijolos",
    },
    {
      image: image7,
      title: "Diversos",
    },
  ]

  return (
    <div
      className={tab === 5 ? "tab-content active" : "tab-content"}
      data-index="4"
    >
      <div className="flex-wrapper">
        <div className="left">
          <div className="left-title">
            <div className="line">
              <span data-index="1">O</span>
              <span data-index="2">que</span>
              <span data-index="3">você</span>
              <span data-index="4">irá</span>
            </div>

            <div className="line">
              <span data-index="5">pintar</span>
              <span data-index="6">é...</span>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="right-blocks">
            {items.map((item, index) => (
              <div
                className={step === item.title ? "item active" : "item"}
                key={`multi-step-tab-5-item-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
                onClick={() =>
                  step === item.title ? setTab(tab + 1) : setStep(item.title)
                }
              >
                <div className="item-image">
                  <img src={item.image} alt="Imagem - Suvinil" />
                </div>

                <div className="item-title">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step5
