import React, { useEffect } from "react"

import Button from "components/core/Button"

const Data = () => {
  useEffect(() => {
    const page = document.querySelector("#page-profile")
    const section = page.querySelector(".section-account")
    const data = section.querySelector(".data")
    const groups = data.querySelectorAll(".field-group")

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      const field = group.querySelector("input")

      field.addEventListener("focus", () => group.classList.add("focus"))

      field.addEventListener("blur", function () {
        group.classList.remove("focus")
        if (field.value.length === 0) {
          group.classList.remove("not-empty")
        } else {
          group.classList.add("not-empty")
        }
      })
    }
  }, [])

  return (
    <div className="data">
      <div className="data-title">
        Edite os campos abaixo e clique em "Salvar alterações":
      </div>

      <div className="data-form">
        <div className="form-line">
          <div className="field-group">
            <div className="placeholder">Nome</div>
            <input type="text" />
          </div>

          <div className="field-group">
            <div className="placeholder">E-mail</div>
            <input type="email" />
          </div>
        </div>

        <div className="form-line">
          <div className="field-group">
            <div className="placeholder">CPF</div>
            <input type="text" />
          </div>

          <div className="field-group">
            <div className="placeholder">Logradouro</div>
            <input type="text" />
          </div>
        </div>

        <div className="form-line">
          <div className="flex">
            <div className="field-group">
              <div className="placeholder">Número</div>
              <input type="text" />
            </div>

            <div className="field-group">
              <div className="placeholder">Complemento</div>
              <input type="text" />
            </div>
          </div>

          <div className="field-group">
            <div className="placeholder">Telefone</div>
            <input type="text" />
          </div>
        </div>

        <div className="separator">Alterar senha</div>

        <div className="form-line">
          <div className="field-group">
            <div className="placeholder">Senha atual</div>
            <input type="password" />
          </div>
        </div>

        <div className="form-line">
          <div className="field-group">
            <div className="placeholder">Nova senha</div>
            <input type="password" />
          </div>
        </div>

        <div className="form-submit">
          <Button>Salvar alterações</Button>
        </div>
      </div>
    </div>
  )
}

export default Data
