import React from "react"

import SpanError from "components/core/SpanError"

import { Container, InputStyle } from "./FormInputStyle"

const FormInput = ({
  type,
  id,
  name,
  value,
  onChange,
  onBlur,
  isError,
  error,
  children,
  isSearch,
  clear,
  disabled,
  placeholder,
  width,
  mb,
  bg,
}) => (
  <Container mb={mb}>
    <InputStyle
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isError={isError}
      isSearch={isSearch}
      placeholder={placeholder}
      disabled={disabled}
      width={width}
      bg={bg}
    />
    {isError && <SpanError>{error}</SpanError>}
    {value && clear}
  </Container>
)

export default FormInput
