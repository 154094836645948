import React from "react"
import Header from "components/core/layout/Header"
import SectionHero from "components/presentation/product/SectionHero"
import SectionDescription from "components/presentation/product/SectionDescription"
import SectionEnvironments from "components/presentation/product/SectionEnvironments"
import SectionProducts from "components/presentation/general/SectionProducts"
import SectionBanners from "components/presentation/general/SectionBanners"
import SectionPainters from "components/presentation/general/SectionPainters"
import SectionVideos from "components/presentation/general/SectionVideos"
import Footer from "components/core/layout/Footer"

import "./product.scss"

const Products = () => (
  <main id="page-product" className="page">
    <Header />
    <SectionHero />
    <SectionDescription />
    <SectionEnvironments />
    <SectionProducts />
    <SectionBanners secondaryStyle />
    <SectionPainters />
    <SectionVideos />
    <Footer />
  </main>
)

export default Products
