import styled from "styled-components"
import NewsLetterBg from "../../../assets/img/newsletter.png"

export const NewsLetter = styled.div`
  background-image: url(${NewsLetterBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 268px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 55px;

  .container {
    display: flex;
    align-items: flex-end;
  }

  .newsletter-content {
    position: relative;
    bottom: -4px;
  }

  h4 {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  span {
    color: white;
    font-size: 14px;
    letter-spacing: -0.05em;
    line-height: 20px;
  }

  input {
    width: 255px;
    height: 50px;
    border: 1px solid white;
    border-radius: 8px;
    padding: 0 18px;
    background-color: transparent;
    font-family: Suvinil Sans;
    font-size: 12px;
    line-height: 20px;
    margin-left: 34px;
    color: white;
    outline: none;

    &::placeholder {
      color: white;
    }
  }

  button {
    margin-left: 15px;
  }
`
