import React from "react"
import Header from "../../components/core/layout/Header"
import SectionSlide from "components/presentation/home/SectionSlide"
import SectionBlocks from "components/presentation/home/SectionBlocks"
import SectionBestSellers from "components/presentation/home/SectionBestSellers"
import SectionBanners from "components/presentation/general/SectionBanners"
import SectionFeaturedProducts from "components/presentation/home/SectionFeaturedProducts"
import Footer from "../../components/core/layout/Footer"

import "./home.scss"

const Home = () => {
  return (
    <>
      <main id="page-home" className="page">
        <Header />
        <SectionSlide />
        <SectionBlocks />
        <SectionBestSellers />
        <SectionBanners />
        <SectionFeaturedProducts />
        <Footer />
      </main>
    </>
  )
}

export default Home
