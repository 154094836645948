import React from "react"

const SectionDescription = () => (
  <section className="section-description">
    <div className="container">
      <div className="flex-wrapper">
        <div className="column">
          <h3 className="column-title">Sobre o produto</h3>

          <div className="column-description">
            <p>
              Suvinil Fosco Completo combina com todos os ambientes da sua casa.
              A tinta tem um acabamento fosco incrível com alta cobertura e
              resistência, que cobre a casa de beleza e elogios. Além de não
              deixar cheiro. É difícil não se apaixonar!
            </p>
          </div>

          <div className="column-text">
            <p>
              Imagem meramente ilustrativa.
              <br />O envio do produto na nova embalagem, está condicionado à
              disponibilidade de estoque.
            </p>
          </div>
        </div>

        <div className="column">
          <h3 className="column-title">Informações técnicas</h3>

          <div className="column-list">
            <ul>
              <li>Acabamento: Acetinado</li>
              <li>
                Quantidade necessária de demãos: Duas a três demãos com o
                intervalo de 2 horas.
              </li>
              <li>
                Secagem: Ao toque: 30 minutos / Entre demãos: 2 horas / Final: 5
                horas.
              </li>
              <li>
                Diluição: 10% (Água potável) / Pistola: Diluir com 30% de agua,
                pressão entre 2,2 e<br /> 2,8kgf/cm² ou 30 a 35 lbs/pol².
                <br />
                <strong>800ml:</strong> Rendimento até 17 m²/demão.
                <br />
                <strong>3,2L:</strong> Rendimento até 67 m²/demão
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionDescription
