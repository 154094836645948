import React from "react"
import Header from "components/core/layout/Header"
import SectionHero from "components/presentation/products/SectionHero"
import SectionProducts from "components/presentation/products/SectionProducts"
import SectionBanners from "components/presentation/general/SectionBanners"
import Footer from "components/core/layout/Footer"

import "./products.scss"

const Products = () => (
  <main id="page-products" className="page">
    <Header />
    <SectionHero />
    <SectionProducts />
    <SectionBanners />
    <Footer />
  </main>
)

export default Products
