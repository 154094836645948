import React from "react"

import "./style.scss"
import image1 from "assets/img/general/painters-image-1.png"
import image2 from "assets/img/general/painters-image-2.png"
import image3 from "assets/img/general/painters-image-3.png"
import image4 from "assets/img/general/painters-image-4.png"

const SectionPainters = () => {
  const painters = [
    {
      image: image1,
      title: "Marcos da Silva Junior",
      ddd: "031",
      number: "99344.1232",
      description: "Pintura interna, alveria,\ngesso e metais",
    },
    {
      image: image2,
      title: "Abel Alencar",
      ddd: "031",
      number: "98362.3321",
      description: "Pintura interna, alveria,\ngesso e metais",
    },
    {
      image: image3,
      title: "Bernardo Garcia",
      ddd: "031",
      number: "99283.0923",
      description: "Pintura interna, alveria,\ngesso e metais",
    },
    {
      image: image4,
      title: "Zênio Costa",
      ddd: "031",
      number: "99784.3411",
      description: "Pintura interna, alveria,\ngesso e metais",
    },
  ]

  return (
    <section className="section-painters">
      <div className="container">
        <div className="headline">
          <h2 className="headline-title">Pintores recomendados</h2>

          <div className="headline-description">
            <p>
              Caso você precise de uma ajudinha, listamos alguns pintores
              próximos ao seu endereço
            </p>
          </div>
        </div>

        <div className="flex-wrapper">
          {painters.map((item, index) => (
            <div className="item" key={`painters-item-${index}`}>
              <div className="item-image">
                <img src={item.image} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <div className="item-content--title">{item.title}</div>

                <div className="item-content--phone">
                  <span>{item.ddd}</span> {item.number}
                </div>

                <div className="item-content--description">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionPainters
