const urls = {
  ROUTES: {
    APP: { path: "/", name: "" },
    GET_INSPIRED: { path: "/inspire-se", name: "" },
    PRODUCT: { path: "/produto", name: "" },
    PRODUCTS: { path: "/produtos", name: "" },
    MULTI_STEP: { path: "/multistep", name: "" },
    PROFILE: { path: "/perfil", name: "" },
    CHECKOUT: { path: "/checkout", name: "" },
    CART: { path: "/carrinho", name: "" },
  },
  LINKS: {
    APP: "/",
    CART: "/carrinho",
    GET_INSPIRED: "/inspire-se",
    PRODUCT: "/produto",
    PRODUCTS: "/produtos",
    MULTI_STEP: "/multistep",
    PROFILE: "/perfil",
  },
}

export default urls
