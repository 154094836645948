import React from "react"

export const arrowLeft = (
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13L1.27972 7.73383C0.90676 7.31773 0.90676 6.68227 1.27972 6.26618L6 1"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)

export const arrowRight = (
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5.72028 6.26617C6.09324 6.68227 6.09324 7.31773 5.72028 7.73382L1 13"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)
