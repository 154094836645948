import React from "react"

const Step1 = ({ tab, setTab, step, setStep }) => {
  const items = [
    {
      title: "Quero mudar meu ambiente",
    },
    {
      title: "Renovar a decoração",
    },
    {
      title: "Mudança de casa",
    },
    {
      title: "Valorizar imóvel",
    },
    {
      title: "Chegada de bebê",
    },
    {
      title: "Quero resolver uma patologia (mofo, alergia, etc)",
    },
    {
      title: "Quero fazer uma pequena mudança na decoração",
    },
  ]

  return (
    <div
      className={tab === 1 ? "tab-content active" : "tab-content"}
      data-index="0"
    >
      <div className="message">
        <div className="message-title">Olá!</div>

        <div className="message-description">
          <p>
            Vamos te ajudar a encontrar a cor perfeita e para isso vamos
            precisar fazer umas perguntas:
          </p>
        </div>
      </div>

      <div className="flex-wrapper">
        <div className="left">
          <div className="left-title">
            <div className="line">
              <span data-index="1">Vamos</span>
              <span data-index="2">começar,</span>
              <span data-index="3">qual</span>
              <span data-index="4">é</span>
              <span data-index="5">o</span>
            </div>

            <div className="line">
              <span data-index="6">motivo</span>
              <span data-index="7">da</span>
              <span data-index="8">sua</span>
              <span data-index="9">pintura?</span>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="right-list">
            {items.map((item, index) => (
              <button
                type="button"
                className={step === item.title ? "active" : ""}
                key={`multi-step-tab-1-item-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
                onClick={() =>
                  step === item.title ? setTab(tab + 1) : setStep(item.title)
                }
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1
