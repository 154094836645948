import React from "react"

import { arrowRight } from "assets/vectors/general/arrows"
import imageBackground from "assets/img/products/hero-background.jpg"
import imageBlock from "assets/img/products/hero-block.jpg"

const SectionHero = () => (
  <section className="section-hero">
    <div className="background">
      <img src={imageBackground} alt="Imagem - Suvinil" />
    </div>

    <div className="wrapper">
      <div className="container">
        <div className="content">
          <h1 className="content-title">
            Aqui estão nossas sugestões para você
          </h1>

          <div className="content-description">
            <p>
              A Suvinil preparou diversas fontes de inspiração para você somar
              as suas ideias e ainda aprender com tutoriais e dicas a obter os
              melhores resultados.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="block">
      <button
        type="button"
        className="block-link"
        title="Abrir modal"
        aria-label="Abrir modal"
        onClick={() =>
          document.querySelector(".modal-simulator").classList.add("active")
        }
      >
        Ir para a página
      </button>

      <div className="block-image">
        <img src={imageBlock} alt="Imagem - Suvinil" />
      </div>

      <div className="block-content">
        <h3 className="block-content--title">Simulador</h3>

        <div className="block-content--description">
          <p>
            Simule as cores que mais gostar em
            <br />
            ambientes incríveis
          </p>
        </div>
      </div>

      <div className="block-arrow">{arrowRight}</div>
    </div>
  </section>
)

export default SectionHero
