import React, { useEffect } from "react"

const Sidebar = () => {
  const sidebar = [
    {
      title: "Cores",
      list: [
        "Azul",
        "Amarelo",
        "Vermelho",
        "Verde",
        "Roxo",
        "Laranja",
        "Rosa",
        "Preto",
        "Branco",
      ],
    },
    {
      title: "Tipo de tinta",
      list: [
        "Fosco",
        "Brilho",
        "Acetinado",
        "Texturizada",
        "Marmorato",
        "Fosco 2",
        "Brilho 2",
        "Acetinado 2",
        "Texturizada 2",
        "Marmorato 2",
      ],
    },
    {
      title: "Material",
      list: [
        "Parede de Alvenaria",
        "Madeira",
        "Metal",
        "Área Externa",
        "Gesso",
        "Parede de Alvenaria 2",
        "Madeira 2",
        "Metal 2",
        "Área Externa 2",
        "Gesso 2",
      ],
    },
    {
      title: "Tom da tinta",
      list: [
        "Escura",
        "Clara",
        "Pastel",
        "Vibrante",
        "Vintage",
        "Escura 2",
        "Clara 2",
        "Pastel 2",
        "Vibrante 2",
        "Vintage 2",
      ],
    },
  ]

  useEffect(() => {
    const page = document.querySelector("#page-products")
    const sidebar = page.querySelector(".sidebar")
    const items = sidebar.querySelectorAll(".sidebar-item--listing")

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const list = item.querySelector("ul")
      const listing = list.querySelectorAll("li")

      list.addEventListener("scroll", function () {
        const height = list.offsetHeight
        const scrollTop = list.scrollTop
        const scrollHeight = list.scrollHeight
        const scrollMax = scrollHeight - height

        if (scrollMax - scrollTop <= 7) {
          item.classList.add("scrolled")
        } else {
          item.classList.remove("scrolled")
        }
      })

      for (let y = 0; y < listing.length; y++) {
        const item_list = listing[y]
        const button = item_list.querySelector("button")

        button.addEventListener("click", function () {
          item_list.classList.contains("active")
            ? item_list.classList.remove("active")
            : item_list.classList.add("active")
        })
      }
    }
  }, [])

  return (
    <div className="sidebar">
      <div className="sidebar-search">
        <input type="text" placeholder="Digita para filtrar" />
      </div>

      {sidebar.map((item, index) => (
        <div className="sidebar-item" key={`products-sidebar-item-${index}`}>
          <div className="sidebar-item--title">{item.title}</div>

          <div className="sidebar-item--listing">
            <ul>
              {item.list.map((option, index2) => (
                <li key={`products-sidebar-item-${index}-list-${index2}`}>
                  <button
                    type="button"
                    title="Escolher opção"
                    aria-label="Escolher opção"
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Sidebar
