import React from "react"

import iconVerified from "assets/img/general/verified.svg"
import cardImage1 from "assets/img/get-inspired/cards-image-1.jpg"
import cardImage2 from "assets/img/get-inspired/cards-image-2.jpg"
import cardImage3 from "assets/img/get-inspired/cards-image-3.jpg"
import cardImage4 from "assets/img/get-inspired/cards-image-4.jpg"
import cardImage5 from "assets/img/get-inspired/cards-image-5.jpg"
import cardImage6 from "assets/img/get-inspired/cards-image-6.jpg"
import cardImage7 from "assets/img/get-inspired/cards-image-7.jpg"
import cardImage8 from "assets/img/get-inspired/cards-image-8.jpg"
import cardImage9 from "assets/img/get-inspired/cards-image-9.jpg"
import cardImage10 from "assets/img/get-inspired/cards-image-10.jpg"
import cardImage11 from "assets/img/get-inspired/cards-image-11.jpg"

const Cards = () => {
  const column1 = [
    {
      client: true,
      image: cardImage1,
    },
    {
      client: false,
      image: cardImage5,
    },
  ]

  const column2 = [
    {
      client: false,
      image: cardImage2,
    },
    {
      client: true,
      image: cardImage6,
    },
    {
      client: false,
      image: cardImage9,
    },
  ]

  const column3 = [
    {
      client: true,
      image: cardImage3,
    },
    {
      client: false,
      image: cardImage7,
    },
    {
      client: false,
      image: cardImage10,
    },
  ]

  const column4 = [
    {
      client: false,
      image: cardImage4,
    },
    {
      client: true,
      image: cardImage8,
    },
    {
      client: false,
      image: cardImage11,
    },
  ]

  return (
    <div className="flex-wrapper">
      <div className="column">
        {column1.map((item, index) => (
          <div className="item" key={`cards-column-1-${index}`}>
            <div className="item-image">
              <img src={item.image} alt="Imagem - Suvinil" />
            </div>

            {item.client ? (
              <div className="item-content">
                <img src={iconVerified} alt="Imagem - Suvinil" />
                <span>Ambiente de cliente</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>

      <div className="column">
        {column2.map((item, index) => (
          <div className="item" key={`cards-column-2-${index}`}>
            <div className="item-image">
              <img src={item.image} alt="Imagem - Suvinil" />
            </div>

            {item.client ? (
              <div className="item-content">
                <img src={iconVerified} alt="Imagem - Suvinil" />
                <span>Ambiente de cliente</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>

      <div className="column">
        {column3.map((item, index) => (
          <div className="item" key={`cards-column-3-${index}`}>
            <div className="item-image">
              <img src={item.image} alt="Imagem - Suvinil" />
            </div>

            {item.client ? (
              <div className="item-content">
                <img src={iconVerified} alt="Imagem - Suvinil" />
                <span>Ambiente de cliente</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>

      <div className="column">
        {column4.map((item, index) => (
          <div className="item" key={`cards-column-4-${index}`}>
            <div className="item-image">
              <img src={item.image} alt="Imagem - Suvinil" />
            </div>

            {item.client ? (
              <div className="item-content">
                <img src={iconVerified} alt="Imagem - Suvinil" />
                <span>Ambiente de cliente</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Cards
