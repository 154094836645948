import React from "react"

import { Link } from "react-router-dom"
import urls from "config/constants/urls"
import Button from "components/core/Button"

// import { arrowRight } from "assets/vectors/general/arrows"
import background from "assets/img/general/modal-compare-background.jpg"
import ExitIcon from "assets/icons/close.svg"
import productImage1 from "assets/img/general/product-image-1.png"
import productImage2 from "assets/img/general/product-image-2.png"
import iconListStyle from "assets/img/general/list-style.svg"

import "./modal-compare.scss"

const ModalCompare = () => {
  const products = [
    {
      headlineBackground: "96CBFC",
      headlineColor: "333333",
      headlineTitle: "Bebê feliz",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
    },
    {
      headlineBackground: "8DD8E2",
      headlineColor: "333333",
      headlineTitle: "Olhos claros",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
    },
    {
      headlineBackground: "2DA2B1",
      headlineColor: "FFFFFF",
      headlineTitle: "Esmeralda",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
    },
  ]

  return (
    <div className="modal-compare">
      <div className="modal-compare--wrapper">
        <div
          className="modal-compare--header"
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          <div className="modal-compare--header_title">Comparar tintas</div>

          <button
            type="button"
            className="modal-compare--header_close"
            tile="Fechar modal"
            aria-label="Fechar modal"
            onClick={() =>
              document
                .querySelector(".modal-compare")
                .classList.remove("active")
            }
          >
            <img src={ExitIcon} alt="Imagem - Suvinil" />
          </button>
        </div>

        <div className="modal-compare--content">
          {products.map((item, index) => (
            <div
              className={
                item.headlineBackground
                  ? "product"
                  : "product product-not-headline"
              }
              key={`modal-compare-product-item-${index}`}
            >
              {item.headlineBackground ? (
                <div
                  className="product-background"
                  style={{ backgroundColor: `#${item.headlineBackground}` }}
                ></div>
              ) : (
                ""
              )}

              {item.headlineBackground ? (
                <div className="product-headline">
                  <div className="product-headline--icon">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke={`#${item.headlineColor}`}
                    >
                      <rect
                        x="-0.5"
                        y="0.5"
                        width="19.5398"
                        height="19.5398"
                        transform="matrix(-1 0 0 1 25.9941 3.42334)"
                      />
                      <path d="M7.09625 23.6422L2.28224 11.1258L6.77532 8.55829" />
                    </svg>
                  </div>

                  <div
                    className="product-headline--title"
                    style={{ color: `#${item.headlineColor}` }}
                  >
                    {item.headlineTitle}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="product-image">
                <img src={item.image} alt="Imagem - Suvinil" />
              </div>

              <h3 className="product-title">{item.title}</h3>

              <div className="product-price">
                <div className="product-price--label">A partir de</div>
                <div className="product-price--value">{item.price}</div>
              </div>

              <div className="product-list">
                <div className="product-list--item">
                  <div className="product-list--item_icon">
                    <img src={iconListStyle} alt="Imagem - Suvinil" />
                  </div>

                  <div className="product-list--item_title">
                    Uso interno/externo
                  </div>
                </div>

                <div className="product-list--item">
                  <div className="product-list--item_icon">
                    <img src={iconListStyle} alt="Imagem - Suvinil" />
                  </div>

                  <div className="product-list--item_title">
                    Não deixa cheiro
                  </div>
                </div>

                <div className="product-list--item">
                  <div className="product-list--item_icon">
                    <img src={iconListStyle} alt="Imagem - Suvinil" />
                  </div>

                  <div className="product-list--item_title">
                    Acabamento fosco
                  </div>
                </div>
              </div>

              <Link
                to={urls.LINKS.PRODUCT}
                className="product-cta"
                title="Ver produto"
                aria-label="Ver produto"
              >
                <Button>Ver produto</Button>
              </Link>

              <div className="product-change">
                <span>Alterar produto na comparação</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ModalCompare
