import React from "react"

import { count1, count2 } from "assets/vectors/product/general"
import productImage1 from "assets/img/general/product-image-1.png"
import productImage2 from "assets/img/general/product-image-2.png"
import productImage3 from "assets/img/general/product-general-1.png"

const Cart = ({
  productCount1,
  setProductCount1,
  productCount2,
  setProductCount2,
  productCount3,
  setProductCount3,
  freight,
}) => {
  return (
    <div className="left-items">
      <div className="left-items--line">
        <div className="item">
          <div className="item-headline" style={{ backgroundColor: `#5379DA` }}>
            <div className="item-headline--icon">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke={`#FFFFFF`}
              >
                <rect
                  x="-0.5"
                  y="0.5"
                  width="19.5398"
                  height="19.5398"
                  transform="matrix(-1 0 0 1 25.9941 3.42334)"
                />
                <path d="M7.09625 23.6422L2.28224 11.1258L6.77532 8.55829" />
              </svg>
            </div>

            <div className="item-headline--title" style={{ color: `#FFFFFF` }}>
              Vivacidade
            </div>
          </div>

          <div className="item-image">
            <img src={productImage1} alt="Imagem - Suvinil" />
          </div>

          <div className="item-content">
            <div className="item-content--title">
              Tinta Suvinil
              <br />
              Fosco Completo
            </div>

            <div className="item-content--salesman">
              <span>Vendido por:</span>
              <span>Varejão das Tintas</span>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-count">
            <button
              type="button"
              title="Diminuir"
              aria-label="Diminuir"
              onClick={() =>
                productCount1 === 1 ? "" : setProductCount1(productCount1 - 1)
              }
            >
              {count1}
            </button>

            <span>{productCount1} Un.</span>

            <button
              type="button"
              title="Aumentar"
              aria-label="Aumentar"
              onClick={() =>
                productCount1 === 55 ? "" : setProductCount1(productCount1 + 1)
              }
            >
              {count2}
            </button>
          </div>
        </div>

        <div className="item">
          <div className="item-description">
            {freight !== false ? (
              <div>
                {freight === 0 ? (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      retirada na loja.
                    </span>
                    O produto já estará
                    <br />
                    disponível em <br />
                    27 de Julho
                  </p>
                ) : (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      entrega pelo correios.
                    </span>
                    O produto será
                    <br />
                    entregue até <br />
                    10 de Julho
                  </p>
                )}
              </div>
            ) : (
              <p>
                Selecione uma
                <br />
                opção de frete
              </p>
            )}
          </div>
        </div>

        <div className="item">
          <div className="item-price">
            R$
            {(productCount1 * 125.9).toFixed(2).replace(".", ",")}
          </div>
        </div>
      </div>

      <div className="left-items--line">
        <div className="item">
          <div className="item-headline" style={{ backgroundColor: `#7D0606` }}>
            <div className="item-headline--icon">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke={`#FFFFFF`}
              >
                <rect
                  x="-0.5"
                  y="0.5"
                  width="19.5398"
                  height="19.5398"
                  transform="matrix(-1 0 0 1 25.9941 3.42334)"
                />
                <path d="M7.09625 23.6422L2.28224 11.1258L6.77532 8.55829" />
              </svg>
            </div>

            <div className="item-headline--title" style={{ color: `#FFFFFF` }}>
              Rubi
            </div>
          </div>

          <div className="item-image">
            <img src={productImage2} alt="Imagem - Suvinil" />
          </div>

          <div className="item-content">
            <div className="item-content--title">
              Tinta Suvinil
              <br />
              Fosco Completo
            </div>

            <div className="item-content--salesman">
              <span>Vendido por:</span>
              <span>Varejão das Tintas</span>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-count">
            <button
              type="button"
              title="Diminuir"
              aria-label="Diminuir"
              onClick={() =>
                productCount2 === 1 ? "" : setProductCount2(productCount2 - 1)
              }
            >
              {count1}
            </button>

            <span>{productCount2} Un.</span>

            <button
              type="button"
              title="Aumentar"
              aria-label="Aumentar"
              onClick={() =>
                productCount2 === 55 ? "" : setProductCount2(productCount2 + 1)
              }
            >
              {count2}
            </button>
          </div>
        </div>

        <div className="item">
          <div className="item-description">
            {freight !== false ? (
              <div>
                {freight === 0 ? (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      retirada na loja.
                    </span>
                    O produto já estará
                    <br />
                    disponível em <br />
                    27 de Julho
                  </p>
                ) : (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      entrega pelo correios.
                    </span>
                    O produto será
                    <br />
                    entregue até <br />
                    10 de Julho
                  </p>
                )}
              </div>
            ) : (
              <p>
                Selecione uma
                <br />
                opção de frete
              </p>
            )}
          </div>
        </div>

        <div className="item">
          <div className="item-price">
            R$
            {(productCount2 * 125.9).toFixed(2).replace(".", ",")}
          </div>
        </div>
      </div>

      <div className="left-items--line">
        <div className="item no-headline">
          <div className="item-image">
            <img src={productImage3} alt="Imagem - Suvinil" />
          </div>

          <div className="item-content">
            <div className="item-content--title">
              Massa Corrida <br />
              PVA 1,4 kg
            </div>

            <div className="item-content--salesman">
              <span>Vendido por:</span>
              <span>Varejão das Tintas</span>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="item-count">
            <button
              type="button"
              title="Diminuir"
              aria-label="Diminuir"
              onClick={() =>
                productCount3 === 1 ? "" : setProductCount3(productCount3 - 1)
              }
            >
              {count1}
            </button>

            <span>{productCount3} Un.</span>

            <button
              type="button"
              title="Aumentar"
              aria-label="Aumentar"
              onClick={() =>
                productCount3 === 55 ? "" : setProductCount3(productCount3 + 1)
              }
            >
              {count2}
            </button>
          </div>
        </div>

        <div className="item">
          <div className="item-description">
            {freight !== false ? (
              <div>
                {freight === 0 ? (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      retirada na loja.
                    </span>
                    O produto já estará
                    <br />
                    disponível em <br />
                    27 de Julho
                  </p>
                ) : (
                  <p>
                    <span>
                      Você optou por
                      <br />
                      entrega pelo correios.
                    </span>
                    O produto será
                    <br />
                    entregue até <br />
                    10 de Julho
                  </p>
                )}
              </div>
            ) : (
              <p>
                Selecione uma
                <br />
                opção de frete
              </p>
            )}
          </div>
        </div>

        <div className="item">
          <div className="item-price">
            R$
            {(productCount3 * 27.5).toFixed(2).replace(".", ",")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
