import styled from "styled-components"

export const Footer = styled.footer`
  padding: 0 0 65px;
`

export const FooterSection = styled.div`
  .container {
    display: flex;
    justify-content: center;
  }
`

export const FooterItems = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: ${({ first, last }) => (first ? "75px" : last ? "0" : "150px")};
  flex-shrink: 0;
`

export const FooterItem = styled.span`
  color: ${({ main }) => (main ? "#333333" : "#666666")};
  font-family: Suvinil Sans;
  font-size: ${({ main }) => (main ? "16px" : "12px")};
  margin-bottom: ${({ main }) => (main ? "18px" : "14px")};
  margin-top: ${({ spaced }) => (spaced ? "35px" : "0")};
  font-weight: ${({ main }) => (main ? "bold" : "normal")};
  :hover {
    cursor: ${({ main, list }) => (list ? "" : !main ? "pointer" : "")};
  }

  img {
  }

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    li {
      position: relative;

      &:not(:last-child) {
        margin-right: 32px;
      }

      a {
        display: inline-block;

        svg path {
          transition-property: fill, stroke;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
        }
      }

      &:nth-child(1) a:hover svg path,
      &:nth-child(2) a:hover svg path,
      &:nth-child(3) a:hover svg path {
        stroke: #f66602;
      }

      &:nth-child(4) a:hover svg path {
        fill: #f66602;
      }
    }
  }
`

export const ImgFooter = styled.img`
  margin-bottom: 15px;
  margin-left: ${({ payment }) => (payment ? "5px" : "")};
  margin-right: ${({ payment }) => (payment ? "5px" : "")};
`

export const ImgFooterApp = styled.img`
  width: 125px;
`

export const SiteMap = styled.div`
  height: 56px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: #666666;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 8px;
    }
  }
`
