import React from "react"
import { Link } from "react-router-dom"
import urls from "config/constants/urls"

import "./style.scss"

const CardProduct = ({
  headlineBackground = false,
  headlineColor = false,
  headlineTitle = false,
  image,
  title,
  price,
  installment,
}) => (
  <div
    className={headlineBackground ? "product" : "product product-not-headline"}
  >
    {headlineBackground ? (
      <>
        <div
          className="product-background"
          style={{ backgroundColor: `#${headlineBackground}` }}
        ></div>

        <div
          className="product-shadow"
          style={{ boxShadow: `0 0 30px #${headlineBackground}` }}
        ></div>
      </>
    ) : (
      <div
        className="product-shadow"
        style={{ boxShadow: `0 0 30px rgba(0, 0, 0, .7)` }}
      ></div>
    )}

    <Link
      to={urls.LINKS.PRODUCT}
      className="product-link"
      title="Ver produto"
      aria-label="Ver produto"
    ></Link>

    {headlineBackground ? (
      <div className="product-headline">
        <div className="product-headline--icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={`#${headlineColor}`}
          >
            <rect
              x="-0.5"
              y="0.5"
              width="19.5398"
              height="19.5398"
              transform="matrix(-1 0 0 1 25.9941 3.42334)"
            />
            <path d="M7.09625 23.6422L2.28224 11.1258L6.77532 8.55829" />
          </svg>
        </div>

        <div
          className="product-headline--title"
          style={{ color: `#${headlineColor}` }}
        >
          {headlineTitle}
        </div>
      </div>
    ) : (
      ""
    )}

    <div className="product-image">
      <img src={image} alt="Imagem - Suvinil" />
    </div>

    <h3 className="product-title">{title}</h3>

    <div className="product-price">
      <div className="product-price--label">A partir de</div>
      <div className="product-price--value">{price}</div>
      <div className="product-price--installment">{installment}</div>
    </div>
  </div>
)

export default CardProduct
