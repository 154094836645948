import React from "react"
import Modal from "../../core/Modal"
import * as S from "./styles"
import ExitIcon from "../../../assets/icons/close.svg"
import FormInput from "../../core/FormInput"
import Button from "../../core/Button"

const CreateUser = ({ show, openModalAuth, setShowModal }) => {
  return (
    <Modal size="small" show={show}>
      <S.Header>
        <span>Cadastro</span>
        <S.ExitIcon onClick={() => setShowModal()} src={ExitIcon} />
      </S.Header>
      <S.FormBody>
        <FormInput width="312px" placeholder="Nome" />
        <FormInput width="312px" placeholder="E-mail" />
        <FormInput width="312px" placeholder="Senha" />
        <Button width="312">CADASTRAR</Button>
      </S.FormBody>
      <S.CreateAcount>
        Já possui uma conta?{" "}
        <span onClick={() => openModalAuth()}> Entre aqui</span>
      </S.CreateAcount>
    </Modal>
  )
}

export default CreateUser
