import React from "react"

export const socialFacebook = (
  <svg
    width="12"
    height="21"
    viewBox="0 0 12 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1983 1H8.41691C5.85676 1 3.78134 3.07541 3.78134 5.63557V8.41691H1V12.1254H3.78134V19.5423H7.4898V12.1254H10.2711L11.1983 8.41691H7.4898V5.63557C7.4898 5.12354 7.90488 4.70845 8.41691 4.70845H11.1983V1Z"
      stroke="#999999"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const socialTwitter = (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5078 0.936272C20.62 1.56252 19.637 2.04149 18.5967 2.35476C17.453 1.03968 15.6099 0.578512 13.9816 1.19999C12.3533 1.82147 11.2862 3.3934 11.3096 5.1361V6.06321C8.0004 6.14902 4.86802 4.57239 2.96556 1.86339C2.96556 1.86339 -0.742899 10.2074 7.60112 13.9159C5.69176 15.2119 3.41721 15.8618 1.11133 15.7701C9.45535 20.4057 19.6536 15.7701 19.6536 5.10828C19.6527 4.85004 19.6279 4.59243 19.5794 4.33878C20.5256 3.40563 21.1934 2.22748 21.5078 0.936272Z"
      stroke="#999999"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const socialInstagram = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3402 1H5.69819C3.24045 1 1.24805 2.9924 1.24805 5.45015V15.0921C1.24805 17.5499 3.24045 19.5423 5.69819 19.5423H15.3402C17.7979 19.5423 19.7903 17.5499 19.7903 15.0921V5.45015C19.7903 2.9924 17.7979 1 15.3402 1Z"
      stroke="#999999"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1872 9.72704C14.4218 11.3095 13.6152 12.8641 12.1862 13.5834C10.7572 14.3026 9.02802 14.0244 7.89679 12.8932C6.76556 11.7619 6.48731 10.0328 7.20657 8.60377C7.92582 7.17477 9.48042 6.36812 11.0629 6.60278C12.6788 6.8424 13.9476 8.11116 14.1872 9.72704Z"
      stroke="#999999"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6192 5.63583C16.3872 5.63583 17.0099 5.01321 17.0099 4.24516C17.0099 3.47712 16.3872 2.85449 15.6192 2.85449C14.8511 2.85449 14.2285 3.47712 14.2285 4.24516C14.2285 5.01321 14.8511 5.63583 15.6192 5.63583Z"
      stroke="#999999"
      strokeWidth="1.2"
    />
  </svg>
)

export const socialLinkedin = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.04433 0.378906C1.62051 0.378906 0.457031 1.54238 0.457031 2.9662C0.457031 4.39002 1.62051 5.55349 3.04433 5.55349C4.46814 5.55349 5.63162 4.39002 5.63162 2.9662C5.63162 1.54238 4.46814 0.378906 3.04433 0.378906ZM3.04433 1.24134C4.00205 1.24134 4.76919 2.00848 4.76919 2.9662C4.76919 3.92392 4.00205 4.69106 3.04433 4.69106C2.0866 4.69106 1.31946 3.92392 1.31946 2.9662C1.31946 2.00848 2.0866 1.24134 3.04433 1.24134ZM14.2559 5.98471C12.8772 5.98471 11.94 6.57102 11.2374 7.24467V6.84714C11.2374 6.62136 11.032 6.41595 10.8062 6.41593H7.35648C7.1307 6.41595 6.92527 6.62136 6.92527 6.84714V18.49C6.92527 18.7157 7.1307 18.9212 7.35648 18.9212H11.0218C11.2476 18.9212 11.453 18.7157 11.453 18.49V12.0217C11.453 11.476 11.625 10.9588 11.8775 10.6068C12.13 10.2548 12.4352 10.0813 12.7467 10.0813H13.1779C13.4894 10.0813 13.7946 10.2548 14.0471 10.6068C14.2995 10.9588 14.4715 11.476 14.4715 12.0217V18.49C14.4716 18.7157 14.677 18.9212 14.9028 18.9212H18.5681C18.7939 18.9212 18.9993 18.7157 18.9993 18.49V10.9437C18.9993 9.50623 18.7298 8.2583 17.9617 7.35921C17.1936 6.46012 15.9587 5.98471 14.2559 5.98471ZM1.27904 6.41593C1.06706 6.4359 0.887329 6.63423 0.888243 6.84714V18.49C0.888243 18.7157 1.09368 18.9212 1.31946 18.9212H4.9848C5.21057 18.9212 5.41601 18.7157 5.41601 18.49V6.84714C5.41601 6.62136 5.21057 6.41593 4.9848 6.41593C3.75081 6.41593 2.51 6.41593 1.27904 6.41593ZM14.2559 6.84714C15.8027 6.84714 16.7258 7.24475 17.3014 7.91844C17.877 8.59214 18.1369 9.61673 18.1369 10.9437V18.0587H15.334V12.0217C15.334 11.3013 15.1189 10.6255 14.7478 10.1082C14.3767 9.59088 13.822 9.21883 13.1779 9.21883H12.7467C12.1026 9.21883 11.5479 9.59088 11.1768 10.1082C10.8057 10.6255 10.5906 11.3013 10.5906 12.0217V14.609V18.0587H7.7877V7.27836H10.375V8.3564C10.3749 8.53376 10.4961 8.70482 10.6635 8.76355C10.8308 8.82227 11.0323 8.76444 11.1431 8.62591C11.867 7.72333 12.6385 6.84714 14.2559 6.84714ZM1.75068 7.27836H4.55358V14.609V18.0587H1.75068V7.27836Z"
      fill="#999999"
    />
  </svg>
)
