import React from "react"

const Step7 = ({ tab, setTab, step, setStep }) => {
  const items = [
    {
      title: "Totalmente fosco",
    },
    {
      title: "Com leve brilho",
    },
    {
      title: "Com muito brilho",
    },
    {
      title: "Estou aberto(a)",
    },
  ]

  return (
    <div
      className={tab === 7 ? "tab-content active" : "tab-content"}
      data-index="6"
    >
      <div className="flex-wrapper">
        <div className="left">
          <div className="left-title">
            <div className="line">
              <span data-index="1">Qual</span>
              <span data-index="2">acabamento</span>
            </div>

            <div className="line">
              <span data-index="3">você</span>
              <span data-index="4">deseja?</span>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="right-list">
            {items.map((item, index) => (
              <button
                type="button"
                className={step === item.title ? "active" : ""}
                key={`multi-step-tab-7-item-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
                onClick={() =>
                  step === item.title ? setTab(tab + 1) : setStep(item.title)
                }
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step7
