import React from "react"
import "./style.scss"

import { arrowRight } from "assets/vectors/general/arrows"
import blockImage1 from "assets/img/general/banners-image-1.png"
import blockImage2 from "assets/img/general/banners-image-2.jpg"
import blockImage3 from "assets/img/general/banners-image-3.jpg"

const SectionBanners = ({ secondaryStyle = false }) => (
  <section
    className={
      secondaryStyle ? "section-banners secondary-style" : "section-banners"
    }
  >
    <div className="container">
      <div className="flex-wrapper">
        {!secondaryStyle ? (
          <div className="left">
            <div className="block">
              <div className="block-image">
                <img src={blockImage1} alt="Imagem - Suvinil" />
              </div>

              <div className="block-content">
                <h3 className="block-content--title">Encontre um pintor</h3>

                <div className="block-content--description">
                  <p>
                    Precisa de uma mãozinha?
                    <br />A Suvinil tem uma lista de profissionais
                    especializados para te ajudar.
                  </p>
                </div>

                <div className="block-content--cta">
                  <a
                    href="#provisorio"
                    title="Ir para a página"
                    aria-label="Ir para a página"
                  >
                    <span>Encontre agora</span>
                    {arrowRight}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="right">
          <div className="block">
            <div className="block-image">
              <img src={blockImage2} alt="Imagem - Suvinil" />
            </div>

            <div className="block-content">
              <h3 className="block-content--title">Kits Suvinil</h3>

              <div className="block-content--description">
                <p>
                  Agora ficou ainda mais fácil se divertir e colorir os seus
                  dias.
                </p>
              </div>

              <div className="block-content--cta">
                <a
                  href="#provisorio"
                  title="Ir para a página"
                  aria-label="Ir para a página"
                >
                  <span>Conheça os kits</span>
                  {arrowRight}
                </a>
              </div>
            </div>
          </div>

          <div className="block">
            <div className="block-image">
              <img src={blockImage3} alt="Imagem - Suvinil" />
            </div>

            <div className="block-content">
              <h3 className="block-content--title">Faça você mesmo</h3>

              <div className="block-content--description">
                <p>
                  Separamos diversos tutoriais e dicas de especialistas para
                  você se aventurar.
                </p>
              </div>

              <div className="block-content--cta">
                <a
                  href="#provisorio"
                  title="Ir para a página"
                  aria-label="Ir para a página"
                >
                  <span>Veja as dicas</span>
                  {arrowRight}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionBanners
