import styled from "styled-components"

export default styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 155px;
  width: ${({ width }) => `${width}px`};
  height: 48px;
  padding: 0 25px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    transform-origin: 50% 50%;
    transition-property: opacity, transform;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    z-index: 1;
  }

  &::before {
    background-color: #f66602;
  }

  &::after {
    border: 1px solid #f66602;
    opacity: 0;
    transition-duration: 1s;
    transform: scale(1.05, 1.3);
  }

  span {
    color: white;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.08em;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 2px;
    z-index: 2;
    transition-property: color;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }

  svg {
    margin-top: -3px;
    margin-right: 10px;

    path {
      fill: white;
      transition-property: fill;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    }
  }

  &:hover {
    &::before {
      opacity: 0;
      transform: scale(0.95);
    }

    &::after {
      opacity: 1;
      transform: scale(1);
    }

    span {
      color: #f66602;
    }

    svg path {
      fill: #f66602;
    }
  }
`
