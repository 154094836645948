import styled from "styled-components"

export const PaginationBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PaginationItem = styled.div`
  background: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.03em;
  border: ${({ fixed, selected }) =>
    fixed || selected ? "1px solid #f66602" : " 1px solid #C4C4C4"};
  border-radius: 8px;
  width: 50px;
  height: 50px;
  flex-flow: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  color: ${({ fixed, selected }) =>
    fixed || selected ? "#f66602" : "#C4C4C4"};
  transform: ${({ last }) => (last ? "rotate(-180deg)" : "")};
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    color: #f66602;
    border-color: #f66602;
  }
`
