import React from "react"

import CardProduct from "components/core/CardProduct"

import locationMarker from "assets/img/general/location-marker.png"
import productImage1 from "assets/img/general/product-image-1.png"
import productImage2 from "assets/img/general/product-image-2.png"

const SectionBestSellers = () => {
  const products = [
    {
      headlineBackground: "C39446",
      headlineColor: "FFFFFF",
      headlineTitle: "Quentão",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "7D0606",
      headlineColor: "FFFFFF",
      headlineTitle: "Rubi",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "DCE4D5",
      headlineColor: "333333",
      headlineTitle: "Limonada Refrescante",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "E2AC8D",
      headlineColor: "333333",
      headlineTitle: "Vela aromatizante",
      image: productImage2,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
    {
      headlineBackground: "EDC4C4",
      headlineColor: "333333",
      headlineTitle: "Margaridinha-rosa",
      image: productImage1,
      title: "Tinta Suvinil Fosco Completo",
      price: "R$ 125,90",
      installment: "em até 10x de 12,59 sem juros",
    },
  ]

  return (
    <section className="section-best-sellers">
      <div className="container">
        <div className="headline">
          <h2 className="headline-title">Os mais vendidos de hoje</h2>

          <div className="headline-button">
            <button type="button" title="Alterar cep" aria-label="Alterar cep">
              <img src={locationMarker} alt="Imagem - Suvinil" />

              <span>
                <strong>Clique aqui</strong> e informe o seu CEP para visualizar
                os preços oficiais para a sua localidade
              </span>
            </button>
          </div>
        </div>

        <div className="flex-wrapper">
          {products.map((product, index) => (
            <CardProduct
              headlineBackground={product.headlineBackground}
              headlineColor={product.headlineColor}
              headlineTitle={product.headlineTitle}
              image={product.image}
              title={product.title}
              price={product.price}
              installment={product.installment}
              key={`product-${index}`}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionBestSellers
