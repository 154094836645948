import React from "react"
import * as S from "./styles"
import Button from "../Button"
export default function NewsLetter() {
  return (
    <S.NewsLetter>
      <div className="container">
        <div className="newsletter-content">
          <h4>Suvinews</h4>
          <span>
            Fique por dentro de <br /> ofertas, tendências,
            <br /> novidades e muito mais.
          </span>
        </div>
        <input type="email" placeholder="Insira seu e-mail"></input>
        <Button>Cadastrar</Button>
      </div>
    </S.NewsLetter>
  )
}
