import React, { useState, useEffect } from "react"

import { Link, Redirect } from "react-router-dom"
import urls from "config/constants/urls"
import CreateUser from "components/presentation/CreateUser"
import AuthForm from "components/presentation/AuthForm"

import logo from "assets/img/general/logo.png"
import flagBR from "assets/img/general/flag-br.png"
import iconLogin from "assets/img/general/header-icon-login.png"
import locationMarker from "assets/img/general/location-marker.png"
import { ReactComponent as ArrowDown } from "assets/img/general/header-arrow-down.svg"
import { ReactComponent as Favorites } from "assets/img/general/header-favorites.svg"
import { ReactComponent as Cart } from "assets/img/general/header-cart.svg"

import "./style.scss"

const Header = () => {
  const [redirect, setRedirect] = useState(false)
  const [showAuthModal, setShowModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const openModalAuth = () => {
    setShowModal(!showAuthModal)
    setShowCreateModal(false)
  }

  const openCreateModalAuth = () => {
    setShowModal(!showAuthModal)
    setShowCreateModal(!showCreateModal)
  }

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 50)
  }, [])

  return (
    <>
      <header id="main-header">
        {redirect ? <Redirect to="/produtos" /> : ""}

        <div className="top">
          <div className="container">
            <div className="top-languages">
              <img src={flagBR} alt="Imagem - Suvinil" />
              <ArrowDown />
            </div>

            <div className="top-navigation">
              <ul>
                <li>
                  <span>Acessibilidade</span>
                  <ArrowDown />
                </li>

                <li>
                  <span>Fale conosco</span>
                </li>

                <li>
                  <span>Baixe nosso App</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="center">
          <div className="container">
            <div className="center-logo">
              <Link to="/">
                <img src={logo} alt="Imagem - Suvinil" />
              </Link>
            </div>

            <div className="center-search">
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setRedirect(true)
                }}
              >
                <input
                  type="text"
                  name="search"
                  placeholder="Busque por cor, código ou produto"
                />

                <button
                  type="submit"
                  title="Buscar produtos"
                  aria-label="Buscar produtos"
                ></button>
              </form>
            </div>

            <div className="center-login" onClick={() => openModalAuth()}>
              <div className="center-login--icon">
                <img src={iconLogin} alt="Imagem - Suvinil" />
              </div>

              <div className="center-login--content">
                <div className="center-login--content_title">Login</div>

                <div className="center-login--content_button">
                  <img src={locationMarker} alt="Imagem - Suvinil" />
                  Em Belo Horizonte? <span>Informe seu CEP</span>
                </div>
              </div>
            </div>

            <div className="center-links">
              <div className="center-links--favorites">
                <Favorites />
              </div>

              <div className="center-links--cart">
                <Link to={urls.LINKS.CART}>
                  <Cart />
                  <span>3</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container">
            <div className="bottom-navigation">
              <ul>
                <li>
                  <span>A Suvinil</span>
                  <ArrowDown />
                </li>
                <li>
                  <span>Produtos</span>
                  <ArrowDown />
                </li>
                <li>
                  <span>Acessórios</span>
                  <ArrowDown />
                </li>
                <li>
                  <span>Ferramentas de cor</span>
                  <ArrowDown />
                </li>
                <li>
                  <span>Serviços</span>
                  <ArrowDown />
                </li>
                <li>
                  <span>Color Choice</span>
                </li>
                <li
                  onClick={() =>
                    document
                      .querySelector(".modal-simulator")
                      .classList.add("active")
                  }
                >
                  <span>Simulador</span>
                </li>
                <li>
                  <Link to={urls.LINKS.GET_INSPIRED}>Inspire-se</Link>
                </li>
                <li>
                  <span>Explore</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <AuthForm
        openCreate={openCreateModalAuth}
        setShowModal={setShowModal}
        show={showAuthModal}
      />
      <CreateUser
        setShowModal={setShowCreateModal}
        openModalAuth={openModalAuth}
        show={showCreateModal}
      />
    </>
  )
}

export default Header
