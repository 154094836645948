import React from "react"
import NewsLetter from "../../NewsLetter"
import * as S from "./styles"
import GooglePlay from "../../../../assets/img/google-play.png"
import AppStore from "../../../../assets/img/app-store.png"

import MasterCard from "../../../../assets/img/master-card.png"
import Visa from "../../../../assets/img/visa.png"
import Elo from "../../../../assets/img/elo.png"
import DinersClub from "../../../../assets/img/diners-club.png"
import arrowDown from "assets/img/general/footer-arrow-down.svg"
import {
  socialFacebook,
  socialTwitter,
  socialInstagram,
  socialLinkedin,
} from "assets/vectors/general/social"

import GlobalSign from "../../../../assets/img/global-sign.png"
import ReclameAqui from "../../../../assets/img/reclame-aqui.png"

export default function Footer({ newsletterDisabled }) {
  return (
    <>
      <S.Footer>
        {!newsletterDisabled && <NewsLetter />}
        <S.SiteMap>
          <button>
            Mapa do site
            <img src={arrowDown} alt="Imagem - Suvinil" />
          </button>
        </S.SiteMap>
        <S.FooterSection>
          <div className="container">
            <S.FooterItems first>
              <S.FooterItem main>Institucional</S.FooterItem>
              <S.FooterItem>Quem somos</S.FooterItem>
              <S.FooterItem>Grupo BASF</S.FooterItem>
              <S.FooterItem>Politica de Entrega</S.FooterItem>
              <S.FooterItem>Politica de Trocas</S.FooterItem>
              <S.FooterItem>Politica de Privacidade</S.FooterItem>
              <S.FooterItem>Formas de Pagamento</S.FooterItem>
              <S.FooterItem>Termos e Condições de Uso do site</S.FooterItem>
            </S.FooterItems>
            <S.FooterItems>
              <S.FooterItem main>Serviços</S.FooterItem>
              <S.FooterItem>Encontre seu Pintor</S.FooterItem>
              <S.FooterItem>Consultoria de cor</S.FooterItem>
              <S.FooterItem main spaced>
                Atendimento
              </S.FooterItem>
              <S.FooterItem>Fale Conosco</S.FooterItem>
              <S.FooterItem>Duvidas sobre a loja</S.FooterItem>
              <S.FooterItem>Meus Pedidos</S.FooterItem>
              <S.FooterItem>Minha Conta</S.FooterItem>
            </S.FooterItems>
            <S.FooterItems>
              <S.FooterItem main>Siga a Suvinil</S.FooterItem>
              <S.FooterItem list="true">
                <ul>
                  <li>
                    <a
                      href="#provisorio"
                      title="Ir para a rede social"
                      aria-label="Ir para a rede social"
                    >
                      {socialFacebook}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#provisorio"
                      title="Ir para a rede social"
                      aria-label="Ir para a rede social"
                    >
                      {socialTwitter}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#provisorio"
                      title="Ir para a rede social"
                      aria-label="Ir para a rede social"
                    >
                      {socialInstagram}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#provisorio"
                      title="Ir para a rede social"
                      aria-label="Ir para a rede social"
                    >
                      {socialLinkedin}
                    </a>
                  </li>
                </ul>
              </S.FooterItem>
              <S.FooterItem main>Baixe nosso app</S.FooterItem>
              <S.FooterItem>
                <S.ImgFooterApp src={AppStore} />
              </S.FooterItem>
              <S.FooterItem>
                <S.ImgFooterApp src={GooglePlay} />
              </S.FooterItem>
            </S.FooterItems>
            <S.FooterItems last>
              <S.FooterItem main>Formas de pagamento</S.FooterItem>
              <S.FooterItem>
                <S.ImgFooter payment src={MasterCard} />
                <S.ImgFooter payment src={Visa} />
                <S.ImgFooter payment src={Elo} />
                <S.ImgFooter payment src={DinersClub} />
              </S.FooterItem>
              <S.FooterItem main>Segurança</S.FooterItem>
              <S.FooterItem>
                <S.ImgFooter src={ReclameAqui} />
                <S.ImgFooter style={{ marginLeft: "10px" }} src={GlobalSign} />
              </S.FooterItem>
            </S.FooterItems>
          </div>
        </S.FooterSection>
      </S.Footer>
    </>
  )
}
